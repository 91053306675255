/* eslint-disable no-empty-pattern */
import { loginService } from "../../services/login.service";
import router from "../../router";
import { setCurrentUser } from "../../utils/index";

const state = {
  isLogin: false,
  user: null,
  fullName: null,

  username: null,
  token: null,
  email: null,
  isVerified: null,
  type: null,
};
const actions = {
  async login({ commit }, { username, password, userType }) {
    let result = await loginService.login(username, password, userType);
    if (result.state) {
      const userInfo = result.model;
      commit("loginSuccess");
      commit("loginRequest", { userInfo });
      setCurrentUser(userInfo);
      router.push("/dashboard");
      return result;
    } else {
      return result;
    }
  },
  logout({ commit }) {
    commit("resetState");
    setCurrentUser(null);
    router.push("/pages/login").catch(() => {});
  },
  clearState({ commit }) {
    commit("resetState");
  },
  async dynamicPassword({}, { phone, userType }) {
    let result = await loginService.dynamicPassword(phone, userType);
    return result;
  },
  async changePassword({}, { oldPassword, newPassword }) {
    let result = await loginService.changePassword(oldPassword, newPassword);
    if (result.data.succeeded) {
      router.push("/dashboard");
    } else {
      return result.data.message;
    }
  },
  async currentUserChangePassword({}, { oldPassword, newPassword }) {
    let result = await loginService.changePassword(oldPassword, newPassword);
    return result.data;
  },
};
const mutations = {
  resetState(state) {
    state.isLogin = false;
    state.user = null;
    state.username = null;
    state.fullName = null;
    state.token = null;
    state.email = null;
    state.isVerified = null;
    state.type = null;
  },
  loginRequest(state, user) {
    state.isLogin = true;
    state.user = user.userInfo;
    state.username = user.userInfo.username;
    state.fullName = user.userInfo.fullName;
    state.token = user.userInfo.token;
    state.email = user.userInfo.email;
    state.isVerified = user.userInfo.isVerified;
    state.type = user.userInfo.type;
  },
  loginSuccess(state) {
    state.isLogin = true;
    state.user = null;
    state.username = null;
    state.fullName = null;
    state.token = null;
    state.email = null;
    state.isVerified = null;
    state.type = null;
  },
  loginFailure(state) {
    state.isLogin = false;
  },
};
export default {
  namespaced: true,
  loginState: state,
  actions,
  mutations,
};
