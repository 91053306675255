import axios from "axios";
import {
	apiFarmDateReport,
	apiFinancialAccountReport,
	apiBuyServiceReport,
} from "../constants/config";
import "core-js/stable";

async function GetFarmDate() {
	try {
		let promise = await axios
			.get(apiFarmDateReport, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function GetFinancialAccount() {
	try {
		let promise = await axios
			.get(apiFinancialAccountReport, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function GetBuyService() {
	try {
		let promise = await axios
			.get(apiBuyServiceReport, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}


export const farmerReport = {
	GetFarmDate,
	GetFinancialAccount,
	GetBuyService,
};
