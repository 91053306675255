import axios from "axios";
import {
    apiShopReportGetAllBuyService ,
    apiShopReportGetBuyServiceDetail ,
    apiShopReportGetFarmInfo ,
} from "../constants/config";
import "core-js/stable";


async function ShopReportGetAllBuyService() {
    try {
        let promise = await axios.post(apiShopReportGetAllBuyService, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ShopReportGetAllBuyService' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}

async function ShopReportGetBuyServiceDetail(buyServiceId) {
  try {
        let data = {
            BuyServiceId : buyServiceId
        };
        let promise = await axios.post(apiShopReportGetBuyServiceDetail, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ShopReportGetBuyServiceDetail' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}

async function ShopReportGetFarmInfo(buyServiceId) {
    try {
        let data = {
            BuyServiceId : buyServiceId
        };
        let promise = await axios.post(apiShopReportGetFarmInfo,data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ShopReportGetFarmInfo' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}




export const financailUserShopReportService = {
    ShopReportGetAllBuyService,
    ShopReportGetBuyServiceDetail,
    ShopReportGetFarmInfo,
};
