const state = () => ({
	sidebarShow: "responsive",
	sidebarMinimize: false,
	asideShow: false,
	darkMode: false,
	displayError: false,
	errorTitle: "فرآیند با خطا مواجه گردید",
	errorText: "",
});

// getters
const getters = {
	cartdarkMode: () => {
		return state.darkMode;
	},
};
const actions = {
	setDisplayError({ commit }, { errorText }) {
		commit("displayError", { errorText });
	},
};

const mutations = {
	toggleSidebarDesktop(state) {
		const sidebarOpened = [true, "responsive"].includes(
			state.sidebarShow
		);
		state.sidebarShow = sidebarOpened ? false : "responsive";
	},
	toggleSidebarMobile(state) {
		const sidebarClosed = [false, "responsive"].includes(
			state.sidebarShow
		);
		state.sidebarShow = sidebarClosed ? true : "responsive";
	},
	displayError(state, errorText) {
		console.log("errorText=>???", errorText);
		state.displayError = true;
		state.errorText = errorText;
	},
	clearError(state) {
		state.displayError = false;
		state.errorText = "";
	},
	set(state, [variable, value]) {
		state[variable] = value;
	},
	toggle(state, variable) {
		state[variable] = !state[variable];
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

// export default new Vuex.Store({
//   state,
//   mutations
// })
