import axios from "axios";
import {
  apiAdviceGetById,
  apiGetAllWithUserFarms,
  apiAdviceGetAll,
  apiAdviceWithFarmId,
  apiAdviceGetAllWithGroup,
  apiAdviceInsert,
  apiAdviceEdit,
  apiAdviceDelete,
  apiGetAdviceInfoById
} from "../constants/config";
import "core-js/stable";


async function GetAllWithUserFarms() {
  try {
    let promise = await axios
      .post(apiGetAllWithUserFarms, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetByFarmId(farmId) {
  try {
      let data = {
        farmId : farmId
    };
    let promise = await axios.post(apiAdviceWithFarmId, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from AdviceWithFarmId' + err.response.data.Message);
        });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAdviceInfoById(id) {
  try {
      let data = {
        id : id
    };
    let promise = await axios.post(apiGetAdviceInfoById, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from AdviceWithFarmId' + err.response.data.Message);
        });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}


async function GetAll(SearchName) {
  try {
    let data = {
      SearchName: SearchName
    };
    let promise = await axios
      .post(apiAdviceGetAll, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithGroup(groupId , searchName) {
  try {
    let data = {
      GroupId: groupId,
      SearchName : searchName
    };
    let promise = await axios
      .post(apiAdviceGetAllWithGroup, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiAdviceGetById}/${id}`;
    let promise = await axios
      .get(url, {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(advice) {
  try {
    let promise = await axios
      .post(apiAdviceInsert, advice, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(advice) {
  try {
    let promise = await axios
      .post(apiAdviceEdit, advice, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiAdviceDelete}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const adviceService = {
  GetAllWithUserFarms,
  GetByFarmId,
  GetAdviceInfoById,
  GetAll,
  GetAllWithGroup,
  GetById,
  Insert,
  Edit,
  Delete,
};
