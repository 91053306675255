import {ProvinceService} from "@/services/province.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    console.log("stateInfoService ----",ProvinceService);
    debugger;
    let result = await ProvinceService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize,  name , countryId }) {
    let result = await ProvinceService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name ,
      countryId
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await ProvinceService.GetById(id);
        return result;
  },
  async Insert({}, { stateInfo}) {
    debugger;
    let result = await ProvinceService.Insert(stateInfo);
    return result;
  },
  async Edit({}, { stateInfo }) {
      debugger
    let result = await ProvinceService.Edit(stateInfo);
    return result;
  },
  async Delete({}, { id }) {
    let result = await ProvinceService.Delete(id);
    return result;
  },
  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  ProvinceState: state,
  actions,
  mutations,
};
