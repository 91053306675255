import {helpQuestionService} from "../../services/helpQuestion.service";
import {AppHelpQuestionService} from "../../services/aapHelpQuestion.service"

const state = {};

const actions = {
    async GetAll({ }, { answer, question }) {
        let result = await helpQuestionService.GetAll(answer, question);
        return result;
    },
    async GetById({ }, { id }) {
        let result = await helpQuestionService.GetById(id);
        return result;
    },
    async Insert({ }, { helpQuestion }) {
        let result = await helpQuestionService.Insert(helpQuestion);
        return result;
    },
    async Edit({ }, { helpQuestion }) {
        let result = await helpQuestionService.Edit(helpQuestion);
        return result;
    },
    async Delete({ }, { id }) {
        let result = await helpQuestionService.Delete(id);
        return result;
    },


    async AppGetAll({ }, { answer, question }) {
        let result = await AppHelpQuestionService.GetAll(answer, question);
        return result;
    },
    async AppGetById({ }, { id }) {
        let result = await AppHelpQuestionService.GetById(id);
        return result;
    },
    async AppInsert({ }, { helpQuestion }) {
        let result = await AppHelpQuestionService.Insert(helpQuestion);
        return result;
    },
    async AppEdit({ }, { helpQuestion }) {
        let result = await AppHelpQuestionService.Edit(helpQuestion);
        return result;
    },
    async AppDelete({ }, { id }) {
        let result = await AppHelpQuestionService.Delete(id);
        return result;
    },
};
const mutations = {};
export default {
    namespaced: true,
    helpQuestionState: state,
    actions,
    mutations,
};
