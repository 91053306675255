import {financialUserService} from '../../services/financialUser.service';
import {financialUserAccountService} from '../../services/financialUserAccount.service';
import {financailUserReportService} from '../../services/financailUserReport.service';
import {financailUserShopReportService} from '../../services/financailUserShopReport.service';
import {financialUserDiscountsService} from '../../services/financialUserDiscounts.service';
import {financialUserSentinelService} from '../../services/financialUserSentinelService.service';
import {dietPlanService} from '../../services/dietPlan.service';
import {adviceService} from '../../services/advice.service';


const state = {};

const actions = {

    async UserInventory() {
        let result = await financialUserService.UserInventory();
        return result;
    },
    async UserCostAmount() {
        let result = await financialUserService.UserCostAmount();
        return result;
    },
    async CheckAndCreateAccount() {
        let result = await financialUserService.CheckAndCreateUserFinancialAccount();
        return result;
    },
    async NationalityCodeState() {
        let result = await financialUserService.CheckUserNationalityCodeState();
        return result;
    },
    async AddNationalityCode({ }, { nationalityCode, address }) {
        let result = await financialUserService.UpdateUserNationalityCode(nationalityCode, address);
        return result;
    },
    //==End Of FinancialUser


    async UserAccountInfo({ }, { accountId }) {
        let result = await financialUserAccountService.accountInfo(accountId);
        return result;
    },
    async UserLatestTransaction({ }, { accountId }) {
        let result = await financialUserAccountService.latestTransaction(accountId);
        return result;
    },
    async UserBuyServicePaymentAccount() {
        let result = await financialUserAccountService.buyServicePaymentAccount();
        return result;
    },
    async UserBuyServiceDetailWithBuyServiceId({ }, { buyServiceId }) {
        let result = await financialUserAccountService.buyServiceDetailWithBuyServiceId(buyServiceId);
        return result;
    },
    async UserAccountCustomOfferCode() {
        let result = await financialUserAccountService.accountCustomOfferCode();
        return result;
    },
    async CheckDisCountCode({ }, { accountId, code }) {
        let result = await financialUserAccountService.checkDisCountCode(accountId, code);
        return result;
    },
    async UserIncreseInventoryAccount({ }, { accountId, amount, disCountId, callBackUrl }) {
        let result = await financialUserAccountService.increseAccountInventory(accountId, amount, disCountId, callBackUrl);
        return result.message;
    },
    async GetSuccessTransationWithId({ }, { factId ,transId , idGet }) {
        let result = await financialUserAccountService.GetTransationWithId(factId ,transId , idGet);
        return result;
    },
    //==End Of FinancialUserAccount


    async ReportGetAllTransaction() {
        let result = await financailUserReportService.ReportGetAllTransaction();
        return result;
    },
    async ReportGetAllBuyServiceWithUserId() {
        let result = await financailUserReportService.ReportGetAllBuyServiceWithUserId();
        return result;
    },
    async ReportGetAllBuyService() {
        let result = await financailUserReportService.ReportGetAllBuyService();
        return result;
    },
    // todo: find the main function (duplicated)
    // async ReportGetBuyServiceDetail({ }, { buyServiceId }) {
        // let result = await financailUserReportService.ReportGetBuyServiceDetail(buyServiceId);
        // return result;
    // },
    async ReportBuyServiceGetFarmInfo({ }, { buyServiceId }) {
        let result = await financailUserReportService.ReportGetFarmInfoWithBuyServiceId(buyServiceId);
        return result;
    },
    async ReportGetAllFarmAndDateTimeCurrentUser() {
        let result = await financailUserReportService.ReportGetAllFarmAndDateTimeCurrentUsService();
        return result;
    },
    async ReportGetBuyServiceInfoWithDetailId({ }, { buyServiceDetailId }) {
        let result = await financailUserReportService.ReportGetBuyServiceInfoWithDetailId(buyServiceDetailId);
        return result;
    },
    //==End Of FinancailUserReportService


    async ShopReportGetAllBuyService() {
        let result = await financailUserShopReportService.ShopReportGetAllBuyService();
        return result;
    },
    async ReportGetBuyServiceDetail({ }, { buyServiceId }) {
        let result = await financailUserShopReportService.ShopReportGetBuyServiceDetail(buyServiceId);
        return result;
    },
    async ShopReportGetFarmInfo({ }, { buyServiceId }) {
        let result = await financailUserShopReportService.ShopReportGetFarmInfo(buyServiceId);
        return result;
    },
    //==End Of financailUserShopReportService


    async DiscountsGetAllFestival() {
        let result = await financialUserDiscountsService.DiscountsGetAllFestival();
        return result;
    },
    async DiscountsGetAllPublicOfferCode() {
        let result = await financialUserDiscountsService.DiscountsGetAllPublicOfferCode();
        return result;
    },
    async DiscountsGetAllCustomOfferCode() {
        let result = await financialUserDiscountsService.DiscountsGetAllCustomOfferCode();
        return result;
    },
    async DiscountsGetOfferCodeWithCode({ }, { code }) {
        let result = await financialUserDiscountsService.DiscountsGetOfferCodeWithCode(code);
        return result;
    },
    async DiscountsGetFestivalWithDate() {
        let result = await financialUserDiscountsService.DiscountsGetFestivalWithDate();
        return result;
    },
    //==End Of financialUserDiscountsService


    async SentinelGetFinancialAccountInfo() {
        let result = await financialUserSentinelService.SentinelGetFinancialAccountInfo();
        return result;
    },
    async SentinelGetLatestBuyService() {
        let result = await financialUserSentinelService.SentinelGetLatestBuyService();
        return result;
    },
    async GetBuyServicePerfixWithId({ }, { buyServiceId }) {
        let result = await financialUserSentinelService.GetBuyServicePerfixWithId(buyServiceId);
        return result;
    },
    async DeletePrefixInfo({ }, { id }) {
        let result = await financialUserSentinelService.DeletePrefixInfo(id);
        return result;
    },
    async SentinelGetAllBuyServiceDetailWithId({ }, { buyServiceId }) {
        let result = await financialUserSentinelService.SentinelGetAllBuyServiceDetailWithId(buyServiceId);
        return result;
    },
    async SentinelGetFarmDetailById({ }, { id }) {
        let result = await financialUserSentinelService.SentinelGetFarmDetailById(id);
        return result;
    },
    async SentinelGetFarmDietPlanById({ }, { farmId }) {
        let result = await dietPlanService.GetByFarmId(farmId);
        return result;
    },
    async SentinelGetFarmAdviceById({ }, { farmId }) {
        let result = await adviceService.GetByFarmId(farmId);
        return result;
    },
    async SentinelGetFarmAllDateTimes({ }, { farmId }) {
        let result = await financialUserSentinelService.SentinelGetFarmAllDateTimes(farmId);
        return result;
    },
    async AccountRequestInvoice({ }, { invoiceModel }) {
        let result = await financialUserSentinelService.AccountRequestInvoice(invoiceModel);
        return result;
    },
    async SuccessWithAccountPayment({ }, { successModel }) {
        let result = await financialUserSentinelService.SuccessWithAccountPayment(successModel);
        return result;
    },
    async BankRequestInvoice({ }, { invoiceModel }) {
        let result = await financialUserSentinelService.BankRequestInvoice(invoiceModel);
        return result;
    },
    async SuccessWithBankPayment({ }, { successModel }) {
        let result = await financialUserSentinelService.SuccessWithBankPayment(successModel);
        return result;
    },


    //==End Of financialUserSentinelService
};
const mutations = {};
export default {
    namespaced: true,
    financialUserState: state,
    actions,
    mutations
};
