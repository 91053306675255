import {dietPlanService} from "../../services/dietPlan.service";
import {ProvinceService} from "../../services/province.service";
import {CityService} from "../../services/city.service";
import {ProductService} from "../../services/product.service";


const state = {};

const actions = {
  async GetAll({ }, { searchName }) {
    let result = await dietPlanService.GetAll(searchName);
    return result;
  },
  async GetById({ }, { id }) {
    let result = await dietPlanService.GetById(id);
    return result;
  },
  async Insert({ }, { dietPlan }) {
    let result = await dietPlanService.Insert(dietPlan);
    return result;
  },
  async Edit({ }, { dietPlan }) {
    let result = await dietPlanService.Edit(dietPlan);
    return result;
  },
  async Delete({ }, { id }) {
    let result = await dietPlanService.Delete(id);
    return result;
  },



  async GetAllDietPlanUserFarms({ }, { }) {
    let result = await dietPlanService.GetAllWithUserFarms();
    return result;
  },

  async GetAllProductType({ }, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetAllCountry({ }, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetProductWithType({ }, { productTypeId }) {
    let result = await ProductService.GetAllWithFilter(
      0,
      0,
      null,
      productTypeId,
      null
    );
    return result;
  },
  async GetProvinceWithCountry({ }, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({ }, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  dietPlanState: state,
  actions,
  mutations,
};
