import {ExpertiseService} from "../../services/expertise.service";

const state = {};

const actions = {
  async GetAll({}, {}) {
    let result = await ExpertiseService.GetAll();
    return result;
  },
  async GetById({}, { id }) {
    let result = await ExpertiseService.GetById(id);
        return result;
  },
  async Insert({}, { expertise }) {
    let result = await ExpertiseService.Insert(expertise);
    return result;
  },
  async Edit({}, { expertise }) {
    let result = await ExpertiseService.Edit(expertise);
    return result;
  },
  async Delete({}, { id }) {
    let result = await ExpertiseService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  cityState: state,
  actions,
  mutations,
};
