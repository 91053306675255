/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import {advicerService} from "../../services/advicer.service";
import {ConnectionAdvicerService} from "../../services/connectionAdvicer.service";

const state = {};
const actions = {
  async GetAllPartner({ state }, {}) {
    let result = await advicerService.GetAdvicerPartner(0);
    return result;
  },
  async GetAllPartnerFarms({ state }, { userId, userType }) {
    let result = await ConnectionAdvicerService.GetAdvicerAllEmployerFarm(
      userId,
      userType
    );
    return result;
  },
};
const mutations = {};

export default {
  namespaced: true,
  connectionAdvicerState: state,
  actions,
  mutations,
};
