import {meteolorogyService} from "../../services/meteorology.service";

const state = {};

const actions = {
  async GetAll({ }, { pageNumber, pageSize }) {
    let result = await meteolorogyService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({ }, { pageNumber, pageSize, title, description }) {
    let result = await meteolorogyService.GetAllWithFilter(
      pageNumber,
      pageSize,
      title,
      description
    );
    return result;
  },
  async GetById({ }, { id }) {
    let result = await meteolorogyService.GetById(id);
    return result;
  },
  async Insert({ }, { meteorology }) {
    let result = await meteolorogyService.Insert(meteorology);
    return result;
  },
  async Edit({ }, { meteorology }) {
  let result = await meteolorogyService.Edit(meteorology);
    return result;
  },
  async Delete({ }, { id }) {
    let result = await meteolorogyService.Delete(id);
    return result;
  },
  async GetByIdDetial({ }, { id }) {
    let result = await meteolorogyService.GetByIdDetial(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  meteolorogyState: state,
  actions,
  mutations,
};
