import {indicatorService} from "../../services/indicator.service";
import {ProductService} from "../../services/product.service";

const state = {};
const actions = {
  async GetAll() {
    let result = await indicatorService.GetAll();
    return result;
  },
  async GetById({ }, { id }) {
    let result = await indicatorService.GetById(id);
    return result;
  },
  async Insert({ }, { indicator }) {
    let result = await indicatorService.Insert(indicator);
    return result;
  },
  async Edit({ }, { indicator }) {
    let result = await indicatorService.Edit(indicator);
    return result;
  },
  async Delete({ }, { id }) {
    let result = await indicatorService.Delete(id);
    return result;
  },
  async GetState({ }, { id }) {
    let result = await indicatorService.GetState(id);
    return result;
  },
  async SaveState({ }, { indicator }) {
    let result = await indicatorService.SaveState(indicator);
    return result;
  },




  async GetAllProductType({ }, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetProductWithType({ }, { productTypeId }) {
    let result = await ProductService.GetAllWithFilter(
      0,
      0,
      null,
      productTypeId,
      null
    );
    return result;
  },

};
const mutations = {};
export default {
  namespaced: true,
  indicatorState: state,
  actions,
  mutations,
};
