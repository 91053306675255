/* eslint-disable no-unused-vars */
import { connectionFarmerService } from "../../services/connectionFarmer.service";

const state = {};
const actions = {
  async GetAllFarmerFarmuser({ state }, { searchName }) {
    let result = await connectionFarmerService.GetAllFarmerFarmuser(
      searchName
    );
    return result;
  },
  async FarmerFarmuserFarms({ state }, { farmuserId, canAdd }) {
    let result = await connectionFarmerService.FarmerFarmuserFarms(
      farmuserId,
      canAdd
    );
    return result;
  },
  async FarmerFarmuserDetailInfo({ state }, { farmuserId }) {
    let result = await connectionFarmerService.FarmerFarmuserDetailInfo(
      farmuserId
    );
    return result;
  },
  async FarmerFarmuserGetInfoWithCode({ state }, { code }) {
    let result =
      await connectionFarmerService.FarmerFarmuserGetInfoWithCode(code);
    return result;
  },
  async FarmerFarmuserRegister({ state }, { farmuserId }) {
    let result = await connectionFarmerService.FarmerFarmuserRegister(
      farmuserId
    );
    return result;
  },
  async FarmerFarmuserLatestTips({ state }, { farmuserId }) {
    let result = await connectionFarmerService.FarmerFarmuserLatestTips(
      farmuserId
    );
    return result;
  },
  async FarmerFarmuserDelete({ state }, { farmuserId }) {
    let result = await connectionFarmerService.FarmerFarmuserDelete(
      farmuserId
    );
    return result;
  },
  async FarmuserDeleteFarm({ state }, { id }) {
    let result = await connectionFarmerService.FarmuserDeleteFarm(
      id
    );
    return result;
  },
  async FarmuserAddFarm({ state }, { farmuserId, farmId }) {
    let result = await connectionFarmerService.FarmuserAddFarm(
      farmuserId,
      farmId
    );
    return result;
  }, 
};
const mutations = {};

export default {
  namespaced: true,
  connectionFarmerFarmuserState: state,
  actions,
  mutations,
};
