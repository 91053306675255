import axios from "axios";
import store from "./store/modules/login";
import theme from "./store";
import { getCurrentUser } from "./utils/index";
import router from "./router";

export default function setup() {
	axios.defaults.timeout = 15000000000;

	// Add a request interceptor
	axios.interceptors.request.use(
		function (config) {
			// Do something before request is sent
			let logedInUser = getCurrentUser();

			if (!logedInUser) return config;

			const isLogin = logedInUser.isLogin;
			const token = logedInUser.token;

			if (isLogin)
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
			return config;
		},
		function (err) {
			// Do something with request error
			return Promise.reject(err);
		}
	);
	///TODO
	// Add a response interceptor
	axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			// console.log(response);
			// console.log(response.data.failed);
			// console.log(response.data.succeeded);

			if (
				response.data.failed === true ||
				response.data.succeeded === false
			) {
				//theme.dispatch("theme/setDisplayError", {
				//	errorText:
				//		response.data.Message || response.data.message,
				//});

				// alert(response.data.failed);
				// alert(response.data.succeeded);
				// alert(response.data.Data);
			}

			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error

			if (
				error.response &&
				error.response.data &&
				error.response.data.Message
			) {
				if (error.response.status === 401) {
					router.push({ name: "Login" });
					return;
				} else if (error.response.status === 404) {
					router.push({ name: "Page404" });

					return;
				} else {
					console.log(
						"error.response.data.Message",
						error.response
					);
					console.log(
						"error.response.data.Message",
						error.response.data
					);
					console.log(
						"error.response.data.Message",
						error.response.data.Message
					);
					//theme.dispatch("theme/setDisplayError", {
					//	errorText: error.response.data.Message,
					//});
					return;
				}
			}
			console.log(
				"error.response.data.Message",
				error.response.data.Message
			);
			//theme.dispatch("theme/setDisplayError", {
			//	errorText:
			//		error.response &&
			//		error.response.data &&
			//		error.response.data.Message
			//			? error.response.data.Message
			//			: error.response.data,
			//});

			return Promise.reject(error);
		}
	);
}

/*
  }, function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log('error');
        console.log('response', error.response);
        console.log('request', error.request);
        console.log('message', error.message);
        console.log('config', error.config);

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);

        return Promise.reject(error);
    });
*/
