import axios from "axios";
import {
  apiMeteorologyGetMessage,
  apiMeteorologyGetWeather,
  apiMeteorologyGetWeatherDaily,
} from "../constants/config";
import "core-js/stable";

async function GetMessage(condition) {
  try {
    let data = {
      Temperature: condition.temp,
      MaxTemperature: condition.maxTemp,
      MinTemperature: condition.minTemp,
      Humidity: condition.humidity,
      WindSpeed: condition.windSpeed,
      ChanceOfRain: condition.chanceOfRine,
      visibility: condition.visibility,
    };
    let promise = await axios
      .post(apiMeteorologyGetMessage, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function CallOpenWeatherMap(lat, lon) {
  try {
    let data = {
      lat: lat,
      lon: lon,
    };
    let promise = await axios
      .post(apiMeteorologyGetWeatherDaily, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from weather" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
} 

export const weatherService = {
  GetMessage,
  CallOpenWeatherMap, 
};
