import axios from "axios";
import {
    apiMeteorologyConditionGetAll ,
    apiMeteorologyConditionFilter ,
    apiMeteorologyConditionGetById ,
    apiMeteorologyConditionInsert ,
    apiMeteorologyConditionEdit ,
    apiMeteorologyConditionDelete ,
} from "../constants/config";

import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiMeteorologyConditionGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Meteorology Condition" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, meteorologyId, conditionType, equal, minValue, maxValue) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      MeteorologyId : meteorologyId,
      ConditionType : conditionType,
      Equal : equal,
      minValue : minValue,
      MaxValue : maxValue,
    };
    let promise = await axios
      .post(apiMeteorologyConditionFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Meteorology Condition" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiMeteorologyConditionGetById}${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(meteorologyCondition) {
  try {
    let data = {
        MeteorologyId: meteorologyCondition.meteorologyId ,
        ConditionType: meteorologyCondition.conditionType ,
        Equal: meteorologyCondition.equal,
        MinValue: meteorologyCondition.minValue ,
        MaxValue: meteorologyCondition.maxValue,
    };
    let promise = await axios
      .post(apiMeteorologyConditionInsert, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(meteorologyCondition) {
  try {
    let data = {
        Id : meteorologyCondition.Id,
        MeteorologyId: meteorologyCondition.meteorologyId ,
        ConditionType: meteorologyCondition.conditionType ,
        Equal: meteorologyCondition.equal,
        MinValue: meteorologyCondition.minValue ,
        MaxValue: meteorologyCondition.maxValue,
    };
    let promise = await axios
      .post(apiMeteorologyConditionEdit, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiMeteorologyConditionDelete}${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Meteorology Condition" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const meteorologyConditionService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
};
