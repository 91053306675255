import axios from "axios";
import {
    apiUrlLogin,
    apiUrlDynamicPassword,
    apiUrlChangePassword
}
    from "../constants/config"

import "core-js/stable";

async function login(username, password, userType) {
    if (username != "" && password != "" && userType != 0) {
        let resultModel = {
            state: false,
            message: "",
            model: null
        };
        try {
            let promise = await axios.post(
                apiUrlLogin, { phoneNumber: username, password: password, userType: userType }, {}
            ).then((res) => {
                if (res.data.succeeded) {
                    resultModel.state = true;
                    resultModel.message = "به صحرا شرق کیمیا خوش آمدید";
                    resultModel.model = {
                        isLogin: true,
                        username: res.data.data.userName,
                        fullName: res.data.data.fullName,
                        token: res.data.data.jwToken,
                        email: res.data.data.email,
                        isVerified: res.data.data.isVerified,
                        type: res.data.data.roles,
                        updatePassword: res.data.data.updatePassword,
                        moreInfo: res.data.data.moreInfo,
                    };
                } else {
                    resultModel.state = false;
                    resultModel.message = res.data.message;
                }
                return resultModel;
            });
            return promise;
        } catch (err) {
            return resultModel;
        }
    }


}

async function dynamicPassword(phone, userType) {
    try {
        let promise = await axios
            .post(apiUrlDynamicPassword, { Phone: phone, UserType: userType }, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from updatePassword" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

async function changePassword(oldPassword, newPassword) {
    try {
        let promise = await axios
            .post(apiUrlChangePassword, { OldPassword: oldPassword, NewPassword: newPassword }, {})
            .then((res) => {
                return res;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from register" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

export const loginService = {
    login,
    dynamicPassword,
    changePassword
};
