import {userTypeCostService} from "../../services/UserTypeCost.service";

const state = {};

const actions = {
    async GetAll() {
        let result = await userTypeCostService.GetAll();
        return result;
    },
    async GetById({ }, { id }) {
        let result = await userTypeCostService.GetById(id);
        return result;
    },
    async Insert({ }, { userTypeCost }) {
        let result = await userTypeCostService.Insert(userTypeCost);
        return result;
    },
    async Edit({ }, { userTypeCost }) {
        let result = await userTypeCostService.Edit(userTypeCost);
        return result;
    },
    async Delete({ }, { id }) {
        let result = await userTypeCostService.Delete(id);
        return result;
    },
};
const mutations = {};
export default {
    namespaced: true,
    userTypeCostState: state,
    actions,
    mutations,
};
