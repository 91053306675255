import {farmerFarmUserService} from "../../services/farmerFarmUser.service";

const state = {};

const actions = {
  async GetAllFarmUserWithFarmerId({}, { farmerId, userType }) {
    let result = await farmerFarmUserService.GetAllFarmUserWithFarmerId(farmerId, userType);
    return result;
  },
  async GetFarmUserWithCode({}, {code}) {
    let result = await farmerFarmUserService.GetFarmUserWithCode(code);
    return result;
  },
  async GetFarmUserDetailWithId({}, {id}) {
    let result = await farmerFarmUserService.GetFarmUserDetailWithId(id);
    return result;
  },
  //
  async AddFarmUserToFarmer({}, {farmUserId}) {
    let result = await farmerFarmUserService.AddFarmUserToFarmer(farmUserId);
    return result;
  },
  async DeleteFarmUser({}, {id}) {
    let result = await farmerFarmUserService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  farmerFarmUserState: state,
  actions,
  mutations,
};
