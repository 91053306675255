import { agentStateService } from "../../services/agentState.service";
import { ProvinceService } from "../../services/province.service";
import { CityService } from "../../services/city.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await agentStateService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Insert({}, { agentState }) {
    let result = await agentStateService.Insert(agentState);
    return result;
  },
  async Delete({}, { id }) {
    let result = await agentStateService.Delete(id);
    return result;
  },

  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetProvinceWithCountry({}, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({}, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },
};

const mutations = {};
export default {
  namespaced: true,
  agentState: state,
  actions,
  mutations,
};
