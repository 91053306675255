import axios from "axios";
import {
    apiAgentStateFilter,
    apiAgentStateGetAll,
    apiAgentStateGetById,
    apiAgentStateGetWithAgentId,
    apiAgentStateInsert,
    apiAgentStateEdit,
    apiAgentStateDelete,
} from "../constants/config";

import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiAgentStateGetAll, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetAllWithFilter(pageNumber, pageSize, agentId, stateId , cityId) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
            AgentId: name,
            StateId: stateId,
            CityId: cityId
        };
        let promise = await axios
            .post(apiAgentStateFilter, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetById(id) {
    try {
        let url = `${apiAgentStateGetById}/${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetWithAgentId(agentId) {
    try {
        let promise = await axios
            .post(`${apiAgentStateGetWithAgentId}/${agentId}`,{}, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Insert(agentState) {
    try {
        let data = {
            AgentId: agentState.agentId,
            StateId: agentState.stateId,
            CityId: agentState.cityId
        };
        let promise = await axios
            .post(apiAgentStateInsert, data, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Edit(agentState) {
    try {
        let promise = await axios
            .post(apiAgentStateEdit,
                {
                    Id: agentState.id,
                    AgentId: agentState.agentId,
                    StateId: agentState.stateId,
                    CityId: agentState.cityId
                }, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Delete(id) {
    try {
        let promise = await axios
            .get(`${apiAgentStateDelete}/${id}`, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

export const agentStateService = {
    GetAll,
    GetAllWithFilter,
    GetById,
    Insert,
    Edit,
    Delete,
    GetWithAgentId
};
