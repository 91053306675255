import {geoDataBaseService} from "../../services/geoDataBase.service";
import {geoDataBaseTypeService} from "../../services/geoDataBaseType.service";

const state = {};
const actions = {
  async GetAll() {
    let result = await geoDataBaseService.GetAll();
    return result;
  },
  async GetGeoType() {
    let result = await geoDataBaseTypeService.GetAll();
    return result;
  },
  async GetById({}, { id }) {
    let result = await geoDataBaseService.GetById(id);
        return result;
  },
  async Insert({}, { geoDataBase }) {
    let result = await geoDataBaseService.Insert(geoDataBase);
    return result;
  },
  async Edit({}, { geoDataBase }) {
    let result = await geoDataBaseService.Edit(geoDataBase);
    return result;
  },
  async Delete({}, { id }) {
    let result = await geoDataBaseService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  bannerState: state,
  actions,
  mutations,
};
