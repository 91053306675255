import {farmOperationService} from "../../services/farmOperation.service";

const state = {};

const actions = {
    async GetAll({ }, { pageNumber, pageSize }) {
        let result = await farmOperationService.GetAll(pageNumber, pageSize);
        return result;
    },
    async GetWithMonth({ }, { month }) {
        let result = await farmOperationService.GetWithMonth(month);
        return result;
    },
    async GetWithDay({ }, { currentDay }) {
        let result = await farmOperationService.GetWithDay(currentDay);
        return result;
    },
    async Filter({ }, { farmIds, date, types, pageNumber, pageSize }) {
        let result = await farmOperationService.GetAllWithFilter(
            farmIds,
            date,
            types,
            pageNumber,
            pageSize,
        );
        return result;
    },
    async GetById({ }, { id }) {
        let result = await farmOperationService.GetById(id);
        return result;
    },
    async Insert({ }, { operation }) {
        let result = await farmOperationService.Insert(operation);
        return result;
    },
    async Edit({ }, { operation }) {
        let result = await farmOperationService.Edit(operation);
        return result;
    },
    async Delete({ }, { id }) {
        let result = await farmOperationService.Delete(id);
        return result;
    },
};
const mutations = {};
export default {
	namespaced: true,
	farmState: state,
	actions,
	mutations,
};
