import axios from "axios";
import {
    apiFarmOperationGetAll,
    apiFarmOperationGetWithMonth,
    apiFarmOperationGetWithDay,
    apiFarmOperationReport,
    apiFarmOperationGetById,
    apiFarmOperationInsert,
    apiFarmOperationEdit,
    apiFarmOperationDelete,
} from "../constants/config"
import "core-js/stable";



async function GetAll(pageNumber, pageSize) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiFarmOperationGetAll, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetWithMonth(month) {
    try {
        let data = {
            month: month
        };
        let promise = await axios
            .post(apiFarmOperationGetWithMonth, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetWithDay(currentDay) {
    try {
        let promise = await axios
            .post(apiFarmOperationGetWithDay, {CurrentDate: currentDay}, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}




async function GetAllWithFilter(farmIds,date, types, pageNumber, pageSize) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
            FarmIds: farmIds,
            Date : date,
            Types : types ,
        };
        let promise = await axios
            .post(apiFarmOperationReport, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetById(id) {
    try {
        let url = `${apiFarmOperationGetById}${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Insert(operation) {
    try {
        let data = {
            farmId: operation.farmId,
            type: operation.type,
            persianStartDateTime: operation.startDateTime,
            persianEndDateTime  : operation.endDateTime,
            description: operation.description,
        };
        let promise = await axios
            .post(apiFarmOperationInsert, data, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Edit(operation) {
    try {
        let data = {
            Id : operation.id,
            farmId: operation.farmId,
            type: operation.type,
            persianStartDateTime: operation.startDateTime,
            persianEndDateTime  : operation.endDateTime,
            description: operation.description,
        };
        let promise = await axios
            .post(apiFarmOperationEdit,data , {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from farmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Delete(id) {
    try {
        let promise = await axios
            .get(`${apiFarmOperationDelete}${id}`, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}


export const farmOperationService = {
    GetAll,
    GetWithMonth,
    GetWithDay,
    GetAllWithFilter,
    GetById,
    Insert,
    Edit,
    Delete
};
