import axios from "axios";
import {
    apiDiscountsGetAllFestival,
    apiDiscountsGetAllPublicOfferCode,
    apiDiscountsGetAllCustomOfferCode,
    apiDiscountsGetOfferCodeWithCode,
    apiDiscountsGetFestivalWithDate
} from "../constants/config";
import "core-js/stable";


async function DiscountsGetAllFestival() {
    try {
        let promise = await axios.post(apiDiscountsGetAllFestival, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from DiscountsGetAllFestival' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function DiscountsGetAllPublicOfferCode() {
    try {
        let promise = await axios.post(apiDiscountsGetAllPublicOfferCode, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from LatestTransaction' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function DiscountsGetAllCustomOfferCode() {
    try {
        let promise = await axios.post(apiDiscountsGetAllCustomOfferCode, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from LatestTransaction' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function DiscountsGetOfferCodeWithCode(code) {
    try {
        let promise = await axios
            .get(`${apiDiscountsGetOfferCodeWithCode}/${code}`, {}).then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function DiscountsGetFestivalWithDate() {
    try {
        let promise = await axios.post(apiDiscountsGetFestivalWithDate, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from LatestTransaction' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
export const financialUserDiscountsService = {
    DiscountsGetAllFestival,
    DiscountsGetAllPublicOfferCode,
    DiscountsGetAllCustomOfferCode,
    DiscountsGetOfferCodeWithCode,
    DiscountsGetFestivalWithDate
};
