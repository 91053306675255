import axios from "axios";
import {
  apiFarmUrl,
  apiFarmFarmerUrl,
  apiFarmFilterUrl,
  apiFarmLegalEntityUrl,
  apiFarmTextValueUrl,
  apiFarmDelete,
  apiFarmUpdate,
  apiGetFarmsList,
  apiCheckMapImageUrl,
  apiFarmCultivationsEndDate,
  apiCreateFarmWithArchive,
  apiDeleteArchive,
} from "@/constants/config";

async function PostFarmerFarm(farmer) {
  try {
    let promise = await axios
      .post(apiFarmFarmerUrl, farmer, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
        return false;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function PostLegalEntityFarm(farm) {
  try {
    let promise = await axios
      .post(apiFarmLegalEntityUrl, farm, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function GetList(farmName, farmerPhone , archive) {
  try {
    let model = {
      FarmName: farmName,
      FarmerPhone: farmerPhone,
      ArchiveFarm : archive
    };
    let promise = await axios
      .post(apiGetFarmsList, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetReport(filters) {
  try {
    let dateFrom = null;
    if (filters.birthdateFrom) {
      const birthdateFrom = filters.birthdateFrom + " 00:00";
      const changedDate = birthdateFrom.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      dateFrom = new Date(changedDate);
    }
    console.log("dateFrom", dateFrom);

    let dateTo = null;
    if (filters.birthdateTo) {
      const birthdateTo = filters.birthdateTo + " 00:00";
      const changedDate = birthdateTo.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      dateTo = new Date(changedDate);
    }
    console.log("dateTo", dateTo);

    let promise = await axios
      .post(apiFarmFilterUrl, {
        birthdateFrom: dateFrom ? dateFrom.toISOString() : null,
        birthdateTo: dateTo ? dateTo.toISOString() : null,
        isVip: filters.isVip,
        state: filters.state,
        city: filters.city,
        district: filters.district,
        town: filters.town,
        type: filters.type,
      })
      .then((res) => {
        console.log("res.data", res.data);
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farm" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetTextValueList(farmerType, farmerId) {
  try {
    let url = apiFarmTextValueUrl;
    if (farmerType) {
      url =
        apiFarmTextValueUrl.substr(0, apiFarmTextValueUrl.length - 1) +
        "?farmerType=" +
        farmerType +
        "&&farmerId=" +
        farmerId;
    }
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farm" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Get(id) {
  try {
    var url = apiFarmUrl + id;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function Put(farm) {
  try {
    let promise = await axios
      .post(apiFarmUpdate, farm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
async function CreateFarmWithArchive(farm) {
  try {
    let promise = await axios
      .post(apiCreateFarmWithArchive, farm, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
        return false;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function CultivationsEndDate(farmId, endDateTime) {
  let model = {
    FarmId: farmId,
    PersianEndDateTime: endDateTime,
  };
  try {
    let promise = await axios
      .post(apiFarmCultivationsEndDate, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiFarmDelete}${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function DeleteArchive(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteArchive}${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function CheckMapImage(info) {
  try {
    let promise = await axios
      .post(apiCheckMapImageUrl, info, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const farmService = {
  PostFarmerFarm,
  PostLegalEntityFarm,
  GetList,
  GetTextValueList,
  GetReport,
  Get,
  Put,
  Delete,
  CheckMapImage,
  CultivationsEndDate,
  CreateFarmWithArchive,
  DeleteArchive
};
