import axios from "axios";
import {
    apiGetAllAgentFarms,
    apiGetAgentFarmDetail,
    apiGetFarmFarmuser,
    apiGetFarmAdvicer,
    apiGetFarmFarmer,
    apiGetAllAgentFarmers,
    apiGetFarmerAllFarms,
    apiGetAllAgentLegalEntities,
    apiLegalEntitySelectedFarms,
    apiGetAllAgentAdvicers,
    apiAdvicerSelectedFarms,
    apiGetAllAgentFarmusers,
    apiFarmuserSelectedFarms

} from "../constants/config"
import "core-js/stable";

async function GetAllFarms(farmName, farmerPhone ,agentState) {
    try {
        let model = {
            FarmName: farmName,
            FarmerPhone: farmerPhone ,
            AgentState : agentState
        };
        let promise = await axios.post(apiGetAllAgentFarms,model, {})
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetAgentFarmDetail(id) {
    try {
        let promise = await axios.get(
            `${apiGetAgentFarmDetail}${id}`, {}
        ).then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });

        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetFarmFarmuser(farmId , searchName) {
    try {
        let model = {
            FarmId : farmId,
            SearchName : searchName,
        };
        let promise = await axios.post(apiGetFarmFarmuser, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetFarmAdvicer(farmId , searchName) {
    try {
        let model = {
            FarmId : farmId,
            SearchName : searchName,
        };
        let promise = await axios.post(apiGetFarmAdvicer, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetFarmFarmer(farmId ) {
    try {
        let model = {
            FarmId : farmId,
        };
        let promise = await axios.post(apiGetFarmFarmer, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetAllAgentFarmers(searchName) {
    try {
        let model = {
            SearchName : searchName,
        }
        let promise = await axios.post(apiGetAllAgentFarmers, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetFarmerAllFarms(farmerId) {
    try {
        let model = {
            FarmerId : farmerId
        };
        let promise = await axios.post(apiGetFarmerAllFarms, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetAllAgentLegalEntities(searchName) {
    try {
        let model = {
            SearchName : searchName,
        };
        let promise = await axios.post(apiGetAllAgentLegalEntities, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function LegalEntitySelectedFarms(legalEntityId) {
    try {
        var model ={
            LegalEntityId : legalEntityId
        };
        let promise = await axios.post(apiLegalEntitySelectedFarms, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetAllAgentAdvicers(searchName) {
    try {
        let model = {
            SearchName : searchName,
        }
        let promise = await axios.post(apiGetAllAgentAdvicers, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function AdvicerSelectedFarms(advicerId) {
    try {
        let model = {
            AdvicerId : advicerId
        };
        let promise = await axios.post(apiAdvicerSelectedFarms, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetAllAgentFarmusers(searchName) {
    try {
        let model = {
            SearchName : searchName,
        }
        let promise = await axios.post(apiGetAllAgentFarmusers, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function FarmuserSelectedFarms(farmuserId) {
    try {
        let model ={
            FarmuserId : farmuserId
        };
        let promise = await axios.post(apiFarmuserSelectedFarms, model)
            .then((res) => {
                return res;
            }).catch((err) => {
                if (err.response)
                    console.log('catch from register' + err.response.data.Message);
            });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}

export const connectionAgentService = {
    GetAllFarms,
    GetAgentFarmDetail,
    GetFarmFarmuser,
    GetFarmAdvicer,
    GetFarmFarmer,
    GetAllAgentFarmers,
    GetFarmerAllFarms,
    GetAllAgentLegalEntities,
    LegalEntitySelectedFarms,
    GetAllAgentAdvicers,
    AdvicerSelectedFarms,
    GetAllAgentFarmusers,
    FarmuserSelectedFarms,
};
