import axios from "axios";
import {
    apiMeteorologyGetAll ,
    apiMeteorologyFilter ,
    apiMeteorologyGetById ,
    apiMeteorologyInsert ,
    apiMeteorologyEdit ,
    apiMeteorologyDelete ,
    apiMeteorologyGetByIdDetail
} from "../constants/config";
import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiMeteorologyGetAll, data, {})
      .then((res) => {
        return res.data.data;
      }).catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, title, description) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      Title: title,
      Description : description
    };
    let promise = await axios
      .post(apiMeteorologyFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiMeteorologyGetById}${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(meteorology) {
    try {
    let data =
        {
            Title : meteorology.title ,
            Description : meteorology.description,
            MeteorologyConditions : meteorology.condition
        };
    let promise = await axios
      .post(apiMeteorologyInsert, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Meteorology" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(meteorology) {
  console.log("meteorology",meteorology);
  try {
    let promise = await axios
      .post(apiMeteorologyEdit,
        {
          Id : meteorology.id,
          Title : meteorology.title,
          Description : meteorology.description,
          MeteorologyConditions : meteorology.condition
        }, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from meteorology" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiMeteorologyDelete}${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from meteorology" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetByIdDetial(id){
  try {
    let url = `${apiMeteorologyGetByIdDetail}${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      }).catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const meteolorogyService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
  GetByIdDetial
};
