import axios from "axios";
import {
  apiTiketGetAllWithUser,
  apiTiketGetAll,
  apiTiketGetAnswer,
  apiTiketGetById,
  apiTiketAnswer,
  apiTiketNew,
  apiTiketClose,
  apiTiketChangeState,
} from "../constants/config";
import "core-js/stable";



async function GetAll() {
  try {
    let promise = await axios
      .post(apiTiketGetAll, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithUser() {
  try {
    let promise = await axios
      .post(apiTiketGetAllWithUser, {})
      .then((res) => {
          return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}



async function GetAnswerByTiketId(id) {
  try {
    let data =  { TiketId: id };
    let promise = await axios
      .post(apiTiketGetAnswer,data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiTiketGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}



async function PostAnswer(tiket) {
  try {
    let promise = await axios
      .post(apiTiketAnswer, tiket, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function PostNew(tiket) {
  try {
    let promise = await axios
      .post(apiTiketNew, tiket, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function ChangeState(tiketId, state) {
  try {
    let data = {
      Id: tiketId,
      State: state,
    };
    let promise = await axios
      .post(apiTiketChangeState, data, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function CloseTiket(tiketId) {
  try {
    let promise = await axios
      .get(`${apiTiketClose}/${tiketId}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}

export const tiketService = {
  GetAll,
  GetAllWithUser,
  GetAnswerByTiketId,
  GetById,
  PostAnswer,
  PostNew,
  CloseTiket,
  ChangeState,
};
