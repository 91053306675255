import {meteorologyConditionService} from "../../services/meteorologyCondition.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await meteorologyConditionService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize,  meteorologyId, conditionType, equal, minValue, maxValue }) {
    let result = await meteorologyConditionService.GetAllWithFilter(
      pageNumber,
      pageSize,
      meteorologyId,
      conditionType,
      equal,
      minValue,
      maxValue
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await meteorologyConditionService.GetById(id);
        return result;
  },
  async Insert({}, { meteorologyCondition }) {
    let result = await meteorologyConditionService.Insert(meteorologyCondition);
    return result;
  },
  async Edit({}, { meteorologyCondition }) {
    let result = await meteorologyConditionService.Edit(meteorologyCondition);
    return result;
  },
  async Delete({}, { id }) {
    let result = await meteorologyConditionService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  meteorologyConditionState: state,
  actions,
  mutations,
};
