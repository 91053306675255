const state = {};
const actions = {
  async copyText({state, action}, {text}) {
    await navigator.clipboard.writeText(text);
  },
};
const mutations = {};

export default {
  namespaced: true,
  tipsState: state,
  actions,
  mutations,
};
