import {productTypeService} from "../../services/productType.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await productTypeService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize, name }) {
    let result = await productTypeService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await productTypeService.GetById(id);
        return result;
  },
  async Insert({}, { productType }) {
    let result = await productTypeService.Insert(productType);
    return result;
  },
  async Edit({}, { productType }) {
    let result = await productTypeService.Edit(productType);
    return result;
  },
  async Delete({}, { id }) {
    let result = await productTypeService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  productTypeState: state,
  actions,
  mutations,
};
