import { adviceGroupService } from "../../services/adviceGroup.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await adviceGroupService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize, name , state }) {
    let result = await adviceGroupService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name,
      state,
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await adviceGroupService.GetById(id);
        return result;
  },
  async Insert({}, { adviceGroup }) {
    let result = await adviceGroupService.Insert(adviceGroup);
    return result;
  },
  async Edit({}, { adviceGroup }) {
    let result = await adviceGroupService.Edit(adviceGroup);
    return result;
  },
  async Delete({}, { id }) {
    let result = await adviceGroupService.Delete(id);
    return result;
  },
  async ChangeState({}, { id }) {
    let result = await adviceGroupService.ChangeState(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  adviceGroupState: state,
  actions,
  mutations,
};
