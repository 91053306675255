import axios from "axios";
import {
    apiGetUserTypeAccessToChat,
    apiGetAllUsersInfoRelated,
    apiGetUserChatHistory,
    apiGetChatDetailWithUserId,
} from "../constants/config";

import "core-js/stable";

async function GetAllUserTypeAccessToChat() {
  try {
    let promise = await axios
      .post(apiGetUserTypeAccessToChat, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllUsersInfoRelated() {
  try {
    let promise = await axios
      .post(apiGetAllUsersInfoRelated, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetUserChatHistory(userType , searchText) {
    try {
        let model ={
            UserType : userType,
            SearchText : searchText,
        };
        let promise = await axios
          .post(apiGetUserChatHistory,model,  {})
          .then((res) => {
            return res.data.data;
          })
          .catch((err) => {
            if (err.response)
              console.log("catch from Country" + err.response.data.Message);
          });

        return promise;
      } catch (err) {
        console.log(err);
      }

      return false;
}
async function GetChatDetailWithUserId(userAnswerId) {
    try {
        let model = {
            userAnswerId : userAnswerId
        };
        let promise = await axios
          .post(apiGetChatDetailWithUserId,model, {})
          .then((res) => {
            return res.data.data;
          })
          .catch((err) => {
            if (err.response)
              console.log("catch from Country" + err.response.data.Message);
          });
        return promise;
      } catch (err) {
        console.log(err);
      }

      return false;
}

export const correspondencesService = {
    GetAllUserTypeAccessToChat,
    GetAllUsersInfoRelated,
    GetUserChatHistory,
    GetChatDetailWithUserId,
};
