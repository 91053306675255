import axios from "axios";
import {
    apiSentinelGetFinancialAccountInfo,
    apiSentinelGetLatestBuyService,
    apiSentinelGetAllBuyServiceDetailWithId,
    apiSentinelGetFarmDetailById,
    apiSentinelGetFarmAllDateTimes,
    apiGetUserCostCenter,
    apiAccountRequestInvoice,
    apiSuccessWithAccountPayment,
    apiBankRequestInvoice,
    apiSuccessWithBankPayment,
    apiGetBuyServicePerfixWithId,
    apiDeletePrefixInfo
} from "../constants/config";
import "core-js/stable";


async function SentinelGetFinancialAccountInfo() {
    try {
        let promise = await axios.post(apiSentinelGetFinancialAccountInfo, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from SentinelGetFinancialAccountInfo' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function SentinelGetLatestBuyService() {
    try {
        let promise = await axios.post(apiSentinelGetLatestBuyService, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from SentinelGetLatestBuyService' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function SentinelGetAllBuyServiceDetailWithId(buyServiceId) {
    try {
        let data = {
            buyServiceId: buyServiceId
        };
        let promise = await axios.post(apiSentinelGetAllBuyServiceDetailWithId, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from SentinelGetAllBuyServiceDetailWithId' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function SentinelGetFarmDetailById(id) {
    try {
        let url = `${apiSentinelGetFarmDetailById}/${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

async function SentinelGetFarmAllDateTimes(farmId) {
    try {
        let url = `${apiSentinelGetFarmAllDateTimes}/${farmId}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetUserCostCenter() {
    try {
        let promise = await axios.post(apiGetUserCostCenter, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

async function AccountRequestInvoice(invoiceModel) {
    try {
        let promise = await axios.post(apiAccountRequestInvoice , invoiceModel, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log( err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function SuccessWithAccountPayment(successModel) {
    try {
        let promise = await axios.post(apiSuccessWithAccountPayment,successModel, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log(err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function BankRequestInvoice(invoiceModel) {
    try {
        let promise = await axios.post(apiBankRequestInvoice,invoiceModel, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log( err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function SuccessWithBankPayment(successModel) {
    try {
        let promise = await axios.post(apiSuccessWithBankPayment,successModel, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log(err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetBuyServicePerfixWithId(buyServiceId){
    try {
        let model = {
            BuyServiceId : buyServiceId
        };
        let promise = await axios.post(apiGetBuyServicePerfixWithId,model, {})
        .then((res) => {
            return res.data;
        })
            .catch((err) => {
                if (err.response)
                    console.log(err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function DeletePrefixInfo(id){
    try {
        let promise = await axios
            .get(`${apiDeletePrefixInfo}/${id}`, {}).then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}

export const financialUserSentinelService = {
    SentinelGetFinancialAccountInfo,
    SentinelGetLatestBuyService,
    SentinelGetAllBuyServiceDetailWithId,
    SentinelGetFarmDetailById,
    SentinelGetFarmAllDateTimes,
    GetUserCostCenter,
    AccountRequestInvoice,
    SuccessWithAccountPayment,
    BankRequestInvoice,
    SuccessWithBankPayment,
    GetBuyServicePerfixWithId,
    DeletePrefixInfo
};
