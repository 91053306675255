import axios from "axios";

export async function getToken({ commit }) {
    const url = "https://services.sentinel-hub.com/oauth/token";

    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");
    params.append("client_id", "7ae09a4e-da33-4441-8dd3-735dbbc1d14a");
    params.append("client_secret", "Dk19j<)[UqDwV9j}Bdy|u;&&Txt+F0oG/;;a&chp");

    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    try {
        await axios
            .post(url, params, config)
            .then((res) => {
                commit("SET_TOKEN", res.data.access_token);
            })
            .catch((err) => {
                if (err.response)
                    console.log(
                        "catch from register" + err.response.data.Message
                    );
            });
    } catch (err) {
        console.log(err);
    }
}