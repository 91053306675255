import {farmCostService} from '../../services/farmCost.service';
// import { getCurrentUser } from "../../utils/index";

const state = {
    // areaString: null,
    // userType: '',

    // userId: '',
    // title: '',
    // areaString: '',
    // productType: '',
    // productName: '',
    // cultivationsType: '',
    // address: '',

    // verifyOtp: false,
    // userName: null,
};

const actions = {

    async GetAll({ }, { pageNumber, pageSize }) {
        let result = await farmCostService.GetAll(pageNumber, pageSize);
        return result;
    },
    async Filter({ }, { farmIds,costTypes, pageNumber, pageSize }) {
        let result = await farmCostService.GetAllWithFilter(
            farmIds,
            costTypes,
            pageNumber,
            pageSize,
        );
        return result;
    },
    async GetById({ }, { id }) {
        let result = await farmCostService.GetById(id);
        return result;
    },
    async Insert({ }, { farmCost }) {
        let result = await farmCostService.Insert(farmCost);
        return result;
    },
    async Edit({ }, { farmCost }) {
        let result = await farmCostService.Edit(farmCost);
        return result;
    },
    async Delete({ }, { id }) {
        let result = await farmCostService.Delete(id);
        return result;
    },

};
const mutations = {};
export default {
    namespaced: true,
    farmState: state,
    actions,
    mutations
};
