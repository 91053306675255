import {bannerProductService} from "../../services/bannerProduct.service";
import {ProductService} from "../../services/product.service";
import {ProvinceService} from "../../services/province.service";
import {CityService} from "../../services/city.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await bannerProductService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize , productId,  name , label , description, type , userType }) {
    let result = await bannerProductService.GetAllWithFilter(
      pageNumber,
      pageSize,
      productId,
      name,
      label,
      description,
      type,
      userType
    );
    return result;
  },
  async GetAllClient({}, {}) {
    let result = await bannerProductService.GetAllClient();
    return result;
  },
  async GetBannerProductDashbord({}, {}) {
    let result = await bannerProductService.GetForDashbord();
    return result;
  },
  async GetById({}, { id }) {
    let result = await bannerProductService.GetById(id);
        return result;
  },
  async Insert({}, { bannerProduct }) {
    let result = await bannerProductService.Insert(bannerProduct);
    return result;
  },
  async Edit({}, { bannerProduct }) {
    let result = await bannerProductService.Edit(bannerProduct);
    return result;
  },
  async Delete({}, { id }) {
    let result = await bannerProductService.Delete(id);
    return result;
  },
  async GetAllProductType({}, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetProductWithType({}, { productTypeId }) {
    let result = await ProductService.GetAllWithFilter(
      0,
      0,
      null,
      productTypeId,
      null
    );
    return result;
  },
  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetProvinceWithCountry({}, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({}, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  bannerProductState: state,
  actions,
  mutations,
};
