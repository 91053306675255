import axios from "axios";
import {
    apiFarmCostGetAll,
    apiFarmCostFilter,
    apiFarmCostGetById,
    apiFarmCostInsert,
    apiFarmCostEdit,
    apiFarmCostDelete,
} from "../constants/config"
import "core-js/stable";




async function GetAll(pageNumber, pageSize) {
    try {
        let data = {
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiFarmCostGetAll, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetAllWithFilter(farmIds,costTypes, pageNumber, pageSize) {
    try {
        let data = {
            FarmIds: farmIds,
            CostTypes: costTypes,
            PageNumber: pageNumber,
            PageSize: pageSize,
        };
        let promise = await axios
            .post(apiFarmCostFilter, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function GetById(id) {
    try {
        let url = `${apiFarmCostGetById}${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Insert(farmCost) {
    try {
        let data = {
            farmId: farmCost.farmId,
            type: farmCost.type,
            unit: farmCost.unit,
            totalCost: farmCost.totalCost,
            quantity: farmCost.quantity,
            description: farmCost.description,
        };
        let promise = await axios
            .post(apiFarmCostInsert, data, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Edit(farmCost) {
    try {
        let promise = await axios
            .post(apiFarmCostEdit,
                {
                    id: farmCost.id,
                    farmId: farmCost.farmId,
                    type: farmCost.type,
                    unit: farmCost.unit,
                    totalCost: farmCost.totalCost,
                    quantity: farmCost.quantity,
                    description: farmCost.description,
                }, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from farmCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Delete(id) {
    try {
        let promise = await axios
            .get(`${apiFarmCostDelete}${id}`, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from FarmCost" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}




export const farmCostService = {
    GetAll,
    GetAllWithFilter,
    GetById,
    Insert,
    Edit,
    Delete
};
