import {CityService} from "../../services/city.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    console.log("CityService ----",CityService);
    let result = await CityService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize, name , stateId}) {
    let result = await CityService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name,
      stateId
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await CityService.GetById(id);
        return result;
  },
  async Insert({}, { city }) {
    let result = await CityService.Insert(city);
    return result;
  },
  async Edit({}, { city }) {
    let result = await CityService.Edit(city);
    return result;
  },
  async Delete({}, { id }) {
    let result = await CityService.Delete(id);
    return result;
  },
  async GetAllState({}, { pageNumber, pageSize }) {
    let result = await CityService.GetAllState(pageNumber, pageSize);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  cityState: state,
  actions,
  mutations,
};
