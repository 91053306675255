import axios from "axios";
import {
    apiGetAllFarmUserWithFarmerIdUrl,
    apiGetFarmUserWithCodeUrl,
    apiGetFarmUserDetailWithIdUrl ,
    apiAddFarmUserToFarmerUrl,
    apiFarmerFarmUserDeleteUrl
} from "../constants/config";

import "core-js/stable";

async function AddFarmUserToFarmer(farmUserId) {
  try {
    let data = {
      FarmUserId: farmUserId,
    };
    let promise = await axios
      .post(apiAddFarmUserToFarmerUrl, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiFarmerFarmUserDeleteUrl}${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}


async function GetAllFarmUserWithFarmerId(farmerId, userType) {
  try {
    let data = {
        FarmerId: farmerId,
        UserType: userType,
    };
    let promise = await axios
      .post(apiGetAllFarmUserWithFarmerIdUrl, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetFarmUserWithCode(code) {
  try {
    let data = {
      Code: code,
    };
    let promise = await axios
      .post(apiGetFarmUserWithCodeUrl, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from City" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetFarmUserDetailWithId(id) {
  try {
    let data = {
      Id: id,
    };
    let promise = await axios
      .post(apiGetFarmUserDetailWithIdUrl, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}


export const farmerFarmUserService = {
    GetAllFarmUserWithFarmerId,
    GetFarmUserWithCode,
    GetFarmUserDetailWithId,
    AddFarmUserToFarmer,
    Delete
};
