import axios from "axios";
import {
  apiGeoDataBaseGetById,
  apiGeoDataBaseGetAll,
  apiGeoDataBaseInsert,
  apiGeoDataBaseEdit,
  apiGeoDataBaseDelete,
} from "../constants/config";

import "core-js/stable";

async function GetAll() {
  try {
    let promise = await axios
      .post(apiGeoDataBaseGetAll, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiGeoDataBaseGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(geoDataBase) {
  try {
    let promise = await axios
      .post(apiGeoDataBaseInsert,  geoDataBase ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from GeoDataBase" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(geoDataBase) {
  try {
    let promise = await axios
      .post(apiGeoDataBaseEdit, geoDataBase ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from geoDataBase" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiGeoDataBaseDelete}/${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const geoDataBaseService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
};
