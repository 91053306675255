import {adviceService} from "../../services/advice.service";
import {adviceGroupService} from "../../services/adviceGroup.service";
import {ProvinceService} from "../../services/province.service";
import {CityService} from "../../services/city.service";
import {ProductService} from "../../services/product.service";

const state = {};

const actions = {

  async GetAllWithUserFarms({ }, { }) {
    let result = await adviceService.GetAllWithUserFarms();
    return result;
  },
  async GetAdviceInfoById({ }, { id }) {
    let result = await adviceService.GetAdviceInfoById(id);
    return result;
  },



  async GetAll({ }, { searchText }) {
    let result = await adviceService.GetAll(searchText);
    return result;
  },
  async GetById({ }, { id }) {
    let result = await adviceService.GetById(id);
    return result;
  },
  async Insert({ }, { advice }) {
    let result = await adviceService.Insert(advice);
    return result;
  },
  async Edit({ }, { advice }) {
    let result = await adviceService.Edit(advice);
    return result;
  },
  async Delete({ }, { id }) {
    let result = await adviceService.Delete(id);
    return result;
  },


  async GetAllProductType({ }, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetProductWithType({ }, { productTypeId }) {
    let result = await ProductService.GetAllWithFilter(
      0,
      0,
      null,
      productTypeId,
      null
    );
    return result;
  },
  async GetAllAdviceGroup({ }, { pageNumber, pageSize }) {
    let result = await adviceGroupService.GetAll(pageNumber, pageSize);
    return result;
  },
  async GetAllCountry({ }, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetProvinceWithCountry({ }, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({ }, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },

};
const mutations = {};
export default {
  namespaced: true,
  adviceState: state,
  actions,
  mutations,
};
