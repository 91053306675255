import {bannerService} from "../../services/banner.service";
import {ProvinceService} from "../../services/province.service";
import {CityService} from "../../services/city.service";

const state = {};

const actions = {
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await bannerService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize,  name , label , description, type , userType}) {
    let result = await bannerService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name,
      label,
      description,
      type,
      userType
    );
    return result;
  },
  async GetBannerDashbord({}, {}) {
    let result = await bannerService.GetForDashbord();
    return result;
  },
  async GetAllClient({}, {}) {
    let result = await bannerService.GetAllClient();
    return result;
  },
  async GetById({}, { id }) {
    let result = await bannerService.GetById(id);
        return result;
  },
  async Insert({}, { banner }) {
    let result = await bannerService.Insert(banner);
    return result;
  },
  async Edit({}, { banner }) {
    let result = await bannerService.Edit(banner);
    return result;
  },
  async Delete({}, { id }) {
    let result = await bannerService.Delete(id);
    return result;
  },

  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },
  async GetProvinceWithCountry({}, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },
  async GetCityWithProvince({}, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  bannerState: state,
  actions,
  mutations,
};
