/* eslint-disable no-empty-pattern */
import { registerService } from "../../services/register.service";
import router from "../../router";
import { setCurrentUser } from "../../utils/index";
import { ProvinceService } from "../../services/province.service";
import { CityService } from "../../services/city.service";

const state = {
  isLogin: false,
  user: null,
  fullName: null,

  username: null,
  token: null,
  email: null,
  isVerified: null,
  type: null,
};

const actions = {
  async checkPhoneState({}, { phone, userType }) {
    let result = await registerService.checkPhoneState(phone, userType);
    return result;
  },

  async sendOtp({}, { phone, userType }) {
    let result = await registerService.sendOtp(phone, userType);
    return result;
  },

  async reSendOtp({}, { phone }) {
    let result = await registerService.sendOtp(phone);
    return result;
  },

  async verifyOtp({}, { phone, userType, verificationCode }) {
    let result = await registerService.verifyOtp(
      phone,
      userType,
      verificationCode
    );
    return result;
  },

  async register({ commit }, { model }) {
    let result = await registerService.Register(model);
    if (result) {
      commit("loginSuccess");
      commit("loginRequest", { result });
      setCurrentUser(result);
      router.push("/dashboard");
    } else {
      return false;
    }
    return result;
  },

  async GetAllCountry({}, { pageNumber, pageSize }) {
    let result = await ProvinceService.GetAllCountry(pageNumber, pageSize);
    return result;
  },

  async GetProvinceWithCountry({}, { countryId }) {
    let result = await ProvinceService.GetAllWithFilter(0, 0, null, countryId);
    return result;
  },

  async GetCityWithProvince({}, { stateId }) {
    let result = await CityService.GetAllWithFilter(0, 0, null, stateId);
    return result;
  },

  clearState({ commit }) {
    commit("resetState");
  },
};
const mutations = {
  resetState(state) {
    state.verifyOtp = false;
    state.userType = null;
  },
  setOtp(state, params) {
    state.verifyOtp = params.result;
    // state.userType = params.userType;
    state.userName = params.mobileNumber;
  },

  setMobileNumber(state, params) {
    state.mobileNumber = params.mobileNumber;
    state.userType = params.localUserType;
  },

  loginRequest(state, user) {
    state.isLogin = true;
    state.user = user.result;

    state.username = user.result.username;
    state.fullName = user.result.fullName;
    state.token = user.result.token;
    state.email = user.result.email;
    state.isVerified = user.result.isVerified;
    state.type = user.result.type;
  },
  loginSuccess(state) {
    state.isLogin = true;
    state.user = null;
    state.username = null;
    state.fullName = null;
    state.token = null;
    state.email = null;
    state.isVerified = null;
    state.type = null;
  },
};
export default {
  namespaced: true,
  registerState: state,
  actions,
  mutations,
};
