import {ProductService} from "../../services/product.service";

const state = {};

const actions = {
  async GetAllProductType({}, { pageNumber, pageSize }) {
    let result = await ProductService.GetAllProductType(pageNumber, pageSize);
    return result;
  },
  async GetAll({}, { pageNumber, pageSize }) {
    let result = await ProductService.GetAll(pageNumber, pageSize);
    return result;
  },
  async Filter({}, { pageNumber, pageSize,  name , productTypeId , greenAlways}) {
    let result = await ProductService.GetAllWithFilter(
      pageNumber,
      pageSize,
      name,
      productTypeId,
      greenAlways
    );
    return result;
  },
  async GetById({}, { id }) {
    let result = await ProductService.GetById(id);
        return result;
  },
  async Insert({}, { product }) {
    console.log("product Insert =====> " ,product);
    let result = await ProductService.Insert(product);
    return result;
  },
  async Edit({}, { product }) {
    let result = await ProductService.Edit(product);
    return result;
  },
  async Delete({}, { id }) {
    let result = await ProductService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  productState: state,
  actions,
  mutations,
};
