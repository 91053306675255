import Vue from "vue";
import CurrentRouter from "vue-router";
import AuthGuard from "../utils/auth.guard";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const contactus = () => import("@/views/pages/contactUs");
const aboutus = () => import("@/views/pages/aboutUs");
const userExpertiseForm = () => import("@/views/expertise/userExpertiseForm");

//Views - Baner
const BannerClient = () => import("@/views/BannerClient/bannerList");

//Views - BannerProduct
const BannerProductClient = () =>
  import("@/views/BannerProductClient/bannerProductList");

// Views - farm
const MyFarmsList = () => import("@/views/farm/farmList");

//Views - advice
const adviceClient = () => import("@/views/adviceClient/adviceList");

//Views - dietPlan
const dietPlanClient = () => import("@/views/dietPlanClient/dietPlanList");

//Views - farm
const farmAddMap = () => import("@/views/farmAdd/farmAddMap");
const farmAddMapDetail = () => import("@/views/farmAdd/farmAddMapDetail");
const farmAddKML = () => import("@/views/farmAdd/farmAddKML");
const farmAddArchive = () => import("@/views/farmAdd/farmAddArchive");

//Views - farmAction
const farmCost = () => import("@/views/farmAction/Cost");
const farmOperation = () => import("@/views/farmAction/Operation");
const farmTension = () => import("@/views/farmAction/Tension");

//Views - Meteorology
const weatherMonitoring = () => import("@/views/weather/Weather");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Policy = () => import("@/views/pages/pprivacy_and_policy");
const IRPolicy = () => import("@/views/pages/ir_pprivacy_and_policy");

const GeoDataBase = () => import("@/views/geoDataBase/geoDataBaseForm");

const Tiket = () => import("@/views/tiket/tiketForm");
const FindAdvicer = () => import("@/views/findAdvicer/findAdvicerForm");
const AppPaymentCheck = () => import("@/views/pages/payment/AppPaymentCheck");
const AppIndicatorPaymentCheck = () =>
  import("@/views/pages/payment/AppIndicatorPaymentCheck");

//#region financial
const buySentinelService = () =>
  import("@/views/financialUser/buySentinelService/buySentinelService");
const userAccountManagement = () =>
  import("@/views/financialUser/userAccountManagement/userAccountManagement");
const farmShopReport = () =>
  import("@/views/financialUser/farmShopReport/farmShopReport");
const financialUserReports = () =>
  import("@/views/financialUser/financialUserReports/financialUserReports");
const financialDiscount = () =>
  import("@/views/financialUser/financialDiscount/financialDiscount");

const correspondences = () =>
  import("@/views/correspondences/correspondencesForm");
// #endregion







//#region Connections

const cooperationRequest = () => import("@/views/cooperationRequest/cooperationRequestForm");



//#region Farmer Connections
const conFarmerLegalEntity = () =>
  import("@/views/conFarmerLegalEntity/conFarmerLegalEntityForm");
const conFarmerAdvicerManagement = () =>
  import("@/views/conFarmerAdvicer/advicerManagement/advicerManagementForm");
const conFarmerAdvicerFarms = () =>
  import("@/views/conFarmerAdvicer/farms/farmerAdvicerFarmsForm");
const conFarmerAdvicerTips = () =>
  import("@/views/conFarmerAdvicer/advicerTips/advicerTipsForm");
const conFarmerFarmuserManagement = () =>
  import("@/views/conFarmerFarmuser/farmuserManagement/farmuserManagementForm");
const conFarmerFarmuserFarms = () =>
  import("@/views/conFarmerFarmuser/farms/farmerFarmuserFarmsForm");
const conFarmerFarmuserTips = () =>
  import("@/views/conFarmerFarmuser/farmuserTips/farmuserTipsForm");
// #endregion




//#region Advicer Connections
const conAdvicerFarms = () => import("@/views/conAdvicerFarms/advicerFarms");
const conAdvicerTips = () => import("@/views/tips/advicerTips");
// #endregion




//#region Farmuser Connections
const conFarmuserFarms = () => import("@/views/conFarmuserFarms/farmuserFarms");
const conFarmuserTips = () => import("@/views/tips/farmuserTips");
// #endregion




//#region Agent Connections
const conAgentFarms = () => import("@/views/agentFarms/agentFarmsForm");
const conAgentFarmer = () => import("@/views/conAgentFarmer/agentFarmerForm");
const conAgentFarmuser = () =>
  import("@/views/conAgentFarmuser/agentFarmuserForm");
const conAgentLegalEntity = () =>
  import("@/views/conAgentLegalEntity/agentLegalEntityForm");
const conAgentAdvicer = () =>
  import("@/views/conAgentAdvicer/agentAdvicerForm");

const helpMove = () => import("@/views/helpMove/helpMoveForm");
const helpQuestion = () => import("@/views/helpQuestion/helpQuestionForm");
// #endregion
// #endregion

Vue.use(CurrentRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "صفحه اصلی",
    component: TheContainer,
    meta: { loginRequired: true },
    children: [
      {
        path: "dashboard",
        name: "نقشه های ماهواره ای زمین",
        component: Dashboard,
      },
      {
        path: "farms",
        name: "زمین های من",
        component: MyFarmsList,
      },
      {
        path: "farmAddMap",
        name: "ثبت اطلاعات زمین با نقشه",
        component: farmAddMap,
      },
      {
        path: "farmAddMapDetail",
        name: "ثبت جزئیات زمین",
        component: farmAddMapDetail,
      },
      {
        path: "farmAddKML",
        name: "ثبت اطلاعات زمین با فایل",
        component: farmAddKML,
      },
      {
        path: "farmAddArchive",
        name: "ثبت اطلاعات زمین از آرشیو",
        component: farmAddArchive,
      },
      {
        path: "weather",
        name: "هواشناسی",
        component: weatherMonitoring,
      },
      {
        path: "contactus",
        name: "تماس با ما",
        component: contactus,
      },
      {
        path: "aboutUs",
        name: "درباه ما",
        component: aboutus,
      },
      {
        path: "cost",
        name: "هزینه ها",
        component: farmCost,
      },
      {
        path: "tension",
        name: "تنش ها",
        component: farmTension,
      },
      {
        path: "operation",
        name: "عملیات ها",
        component: farmOperation,
      },
      {
        path: "bannerClient",
        name: "بنرهای تبلیغاتی",
        component: BannerClient,
      },
      {
        path: "bannerProductClient",
        name: "بنرهای تبلیغاتی محصولات",
        component: BannerProductClient,
      },
      {
        path: "adviceClient",
        name: "توصیه های کاربر",
        component: adviceClient,
      },
      {
        path: "adviceClient/:id",
        name: "توصیه های زمین",
        component: adviceClient,
        props: true,
      },
      {
        path: "dietPlanClient",
        name: "برنامه غذایی کاربر",
        component: dietPlanClient,
      },
      {
        path: "dietPlanClient/:id",
        name: "برنامه غذایی زمین",
        component: dietPlanClient,
        props: true,
      },
      {
        path: "GeoDataBase",
        name: "ژئودیتابیس",
        component: GeoDataBase,
      },
      {
        path: "Tiket",
        name: "تیکت",
        component: Tiket,
      },
      {
        path: "buySentinelService",
        name: "خرید خدمات ماهواره ای",
        component: buySentinelService,
      },
      {
        path: "userAccountManagement",
        name: "افزایش موجودی کیف پول",
        component: userAccountManagement,
      },
      {
        path: "farmShopReport",
        name: "خدمات خریداری شده",
        component: farmShopReport,
      },
      {
        path: "financialUserReports",
        name: "گزارشات و تراکنش های مالی",
        component: financialUserReports,
      },
      {
        path: "financialDiscount",
        name: "جشنواره ها و کدهای تخفیف",
        component: financialDiscount,
      },
      {
        path: "helpQuestion",
        name: "سوالات متداول",
        component: helpQuestion,
      },
      {
        path: "helpMove",
        name: "راهنمای سامانه",
        component: helpMove,
      },
      {
        path: "conFarmerLegalEntity",
        name: "گزارش  حقوقی برای کشاورز",
        component: conFarmerLegalEntity,
      },
      {
        path: "cooperationRequest",
        name: "درخواست همکاری",
        component: cooperationRequest,
      },
      //Farmer
      {
        path: "conFarmerAdvicerManagement",
        name: "مدیریت متخصصان",
        component: conFarmerAdvicerManagement,
      },
      {
        path: "conFarmerAdvicerFarms",
        name: "زمین های متخصصان",
        component: conFarmerAdvicerFarms,
      },
      {
        path: "conFarmerAdvicerTips",
        name: "دستورالعمل های متخصصان",
        component: conFarmerAdvicerTips,
      },
      {
        path: "conFarmerFarmuserManagement",
        name: "بزرگ مالک مدیریت کارمندان مزرعه",
        component: conFarmerFarmuserManagement,
      },
      {
        path: "conFarmerFarmuserFarms",
        name: "بزرگ مالک زمین های کارمندان مزرعه",
        component: conFarmerFarmuserFarms,
      },
      {
        path: "conFarmerFarmuserTips",
        name: "بزرگ مالک دستورالعمل های کارمندان مزرعه",
        component: conFarmerFarmuserTips,
      },
      //advicer
      {
        path: "advicerFarms",
        name: "کارفرمایان و زمین ها متخصص",
        component: conAdvicerFarms,
      },
      {
        path: "advicerTips",
        name: "دستورالعمل ها",
        component: conAdvicerTips,
      },
      //farmuser
      {
        path: "farmuserFarms",
        name: "کارفرمایان و زمین ها کارمندان مزرعه",
        component: conFarmuserFarms,
      },
      {
        path: "farmuserTips",
        name: "دستورالعمل کارمندان",
        component: conFarmuserTips,
      },
      //agent
      {
        path: "conAgentFarms",
        name: "زمین های نمایندگان",
        component: conAgentFarms,
      },
      {
        path: "conAgentFarmer",
        name: "کشاورزان",
        component: conAgentFarmer,
      },
      {
        path: "conAgentLegalEntity",
        name: "حقوقی ها",
        component: conAgentLegalEntity,
      },
      {
        path: "conAgentAdvicer",
        name: "متخصصان شما",
        component: conAgentAdvicer,
      },
      {
        path: "conAgentFarmuser",
        name: "کارمندان مزرعه شما",
        component: conAgentFarmuser,
      },
      {
        path: "correspondences",
        name: "مکاتبات",
        component: correspondences,
      },
      {
        path: "userExpertiseForm",
        name: "پروفایل تخصصی کاربر",
        component: userExpertiseForm,
      },
    ],
  },
  {
    path: "/pages",
    redirect: "/pages/404",
    name: "Pages",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "404",
        name: "Page404",
        component: Page404,
      },
      {
        path: "500",
        name: "Page500",
        component: Page500,
      },
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "policy",
        name: "Policy",
        component: Policy,
      },
      {
        path: "ir_policy",
        name: "IRPolicy",
        component: IRPolicy,
      }, //
      {
        path: "payment-check",
        name: "PaymentCheck",
        component: AppPaymentCheck,
      },
      {
        path: "payment-indicator-check",
        name: "PaymentIndicatorCheck",
        component: AppIndicatorPaymentCheck,
      },
    ],
  },
];

const router = new CurrentRouter({
  linkActiveClass: "open active",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
  routes,
});

router.beforeEach(AuthGuard);
export default router;
