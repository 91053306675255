import axios from "axios";
import {
    apiClientIndicatorGetAll,
    apiClientIndicatorAvailableDay,
    apiClientIndicatorGet,
    apiClientIndicatorGetState,
    apiGetIndicatorDateTimes,
} from "../constants/config";

import "core-js/stable";

async function GetAllWithProduct(data) {
    try {
      let promise = await axios
        .post(apiClientIndicatorGetAll,data, {})
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.response)
            console.log("catch from Country" + err.response.data.Message);
        });
      return promise;
    } catch (err) {
      console.log(err);
    }
    return false;
  }
async function GetAvailableDay(date, farmId) {
    try {
        let data = {
            FarmId: farmId,
            Date : date
        };
        let promise = await axios
            .post(apiClientIndicatorAvailableDay, data, {})
            .then((res) => {
                console.log("getDay",res);
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetFile(data) {
    try {
        let promise = await axios
            .post(apiClientIndicatorGet, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetState(data) {
    try {
        axios.defaults.timeout = 100000;
        let promise = await axios
            .post(apiClientIndicatorGetState, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetDateTimes(id) {
    try {
        let promise = await axios
            .post(apiGetIndicatorDateTimes,{farmId : id} ,{})
            .then((res) => {
                return res;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Country" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
export const indicatorClientService = {
    GetAllWithProduct,
    GetAvailableDay,
    GetFile,
    GetState,
    GetDateTimes,
};
