import axios from "axios";
import {
    apiFinancialUserGetInventory,
    apiFinancialUserTypeCost,
    apiFinancialUserCheck,
    apiUserNationalityCodeState,
    apiAddNationalityCode
} from "../constants/config"
import "core-js/stable";

async function UserInventory() {
    try {
        let promise = await axios.post(apiFinancialUserGetInventory, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function UserCostAmount() {
    try {
        let promise = await axios.post(apiFinancialUserTypeCost, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function CheckAndCreateUserFinancialAccount() {
    try {
        let promise = await axios.post(apiFinancialUserCheck, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function CheckUserNationalityCodeState() {
    try {
        let promise = await axios.post(apiUserNationalityCodeState, {}
        ).then((res) => {
            return res;
        }).catch((err) => {
            if (err)
                console.log('catch from farmUser' + err);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function UpdateUserNationalityCode(code , address) {
    try {
        let promise = await axios.get(`${apiAddNationalityCode}/${code}/${address}`, {}
        ).then((res) => {
            return res;
        }).catch((err) => {
            if (err)
                console.log('catch from farmUser' + err);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
//
export const financialUserService = {
    UserInventory,
    UserCostAmount,
    CheckAndCreateUserFinancialAccount,
    CheckUserNationalityCodeState,
    UpdateUserNationalityCode
};
