import {helpMoveService} from "../../services/helpMove.service";
import {appHelpMoveService} from "../../services/appHelpMove.service";

const state = {};

const actions = {
    async GetAll({ }, { name }) {
        let result = await helpMoveService.GetAll(name);
        return result;
    },
    async GetById({ }, { id }) {
        let result = await helpMoveService.GetById(id);
        return result;
    },
    async Insert({ }, { helpMove }) {
        let result = await helpMoveService.Insert(helpMove);
        return result;
    },
    async Edit({ }, { helpMove }) {
        let result = await helpMoveService.Edit(helpMove);
        return result;
    },
    async Delete({ }, { id }) {
        let result = await helpMoveService.Delete(id);
        return result;
    },


    async AppGetAll({ }, { name }) {
        let result = await appHelpMoveService.GetAll(name);
        return result;
    },
    async AppGetById({ }, { id }) {
        let result = await appHelpMoveService.GetById(id);
        return result;
    },
    async AppInsert({ }, { helpMove }) {
        let result = await appHelpMoveService.Insert(helpMove);
        return result;
    },
    async AppEdit({ }, { helpMove }) {
        let result = await appHelpMoveService.Edit(helpMove);
        return result;
    },
    async AppDelete({ }, { id }) {
        let result = await appHelpMoveService.Delete(id);
        return result;
    },
};
const mutations = {};
export default {
    namespaced: true,
    helpMoveState: state,
    actions,
    mutations,
};
