import axios from "axios";
import {
  apiAdviceGroupGetById,
  apiAdviceGroupFilter,
  apiAdviceGroupGetAll,
  apiAdviceGroupInsert,
  apiAdviceGroupEdit,
  apiAdviceGroupDelete,
  apiAdviceGroupChangeState
} from "../constants/config";
import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiAdviceGroupGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, name , state ) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      Name: name,
      State : state,
    };
    let promise = await axios
      .post(apiAdviceGroupFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiAdviceGroupGetById}/${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(adviceGroup) {
  try {
    let promise = await axios
      .post(apiAdviceGroupInsert, adviceGroup )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(adviceGroup) {
  try {
    let promise = await axios
      .post(apiAdviceGroupEdit, adviceGroup )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiAdviceGroupDelete}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function ChangeState(id){
  try {
    let data = {
      Id : id
    };
    let promise = await axios
      .post(apiAdviceGroupChangeState, data )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from AdviceGroup" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
export const adviceGroupService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
  ChangeState,
};
