import axios from "axios";
import {
  apiAgentUrl,
  apiAgentProfileUrl,
} from "../constants/config";
import "core-js/stable";

async function GetAgentProfile() {
	try {
		let promise = await axios
			.get(apiAgentProfileUrl, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function PutAgent(agent) {
	try {
		let promise = await axios
			.put(
				apiAgentUrl,agent,
				{}
			)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from register" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}

export const agentService = {
  GetAgentProfile,
  PutAgent,
};
