import axios from "axios";
import {
    apiGetAllExpertise,
    apiGetByIdExpertise,
    apiInsertExpertise,
    apiEditExpertise,
    apiDeleteExpertise,
} from "../constants/config";

import "core-js/stable";

async function GetAll() {
    try {
        let promise = await axios
            .post(apiGetAllExpertise, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Expertise" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}

async function GetById(id) {
    try {
        let url = `${apiGetByIdExpertise}/${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from City" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Insert(expertise) {
    try {
        let data = { Name: expertise.name, Label: expertise.label };
        let promise = await axios
            .post(apiInsertExpertise, data, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from expertise" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Edit(expertise) {
    try {
        let promise = await axios
            .post(apiEditExpertise,
                {
                    Id: expertise.id,
                    Name: expertise.name,
                    Label: expertise.label,
                }, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from expertise" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Delete(id) {
    try {
        let promise = await axios
            .get(`${apiDeleteExpertise}${id}`, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Expertise" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}


export const ExpertiseService = {
    GetAll,
    GetById,
    Insert,
    Edit,
    Delete,
};
