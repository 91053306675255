import {indicatorClientService} from "../../services/indicatorClient.service";

const state = {};
const actions = {
    async GetAllWithProduct({ }, model) {
        let result = await indicatorClientService.GetAllWithProduct(model);
        return result;
    },
    async GetAvailableDay({ }, model) {
        let result = await indicatorClientService.GetAvailableDay(model.date, model.farmId);
        return result;
    },
    async GetIndicatorFile({ }, model) {
        let result = await indicatorClientService.GetFile(model);
        return result;
    },
    async GetIndicatorState({ }, model) {
        let result = await indicatorClientService.GetState(model);
        return result;
    },
};
const mutations = {};
export default {
    namespaced: true,
    indicatorClientState: state,
    actions,
    mutations,
};
