import axios from "axios";
import {
    apiGetAllAppHelpQuestion ,
    apiGetByIdAppHelpQuestion,
    apiInsertAppHelpQuestion ,
    apiEditAppHelpQuestion ,
    apiDeleteAppHelpQuestion
} from "../constants/config";
import "core-js/stable";

async function GetAll(answer,question) {
  try {
    let data = {
        Answer: answer,
      Question: question,
    };
    let promise = await axios
      .post(apiGetAllAppHelpQuestion, data, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiGetByIdAppHelpQuestion}/${id}`;
    let promise = await axios
      .get(url, {}).then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(helpQuestion) {
  try {
    let promise = await axios
      .post(apiInsertAppHelpQuestion, helpQuestion,  {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(helpQuestion) {
  try {
    let promise = await axios
      .post(apiEditAppHelpQuestion, helpQuestion,  {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteAppHelpQuestion}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const AppHelpQuestionService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
};
