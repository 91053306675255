import axios from "axios";
import {
	apiLegalEntityTextValueUrl,
	apiFarmerTextValueUrl,
	apiFarmerUrl,
	apiLegalEntityUrl,
	apiFarmerProfileUrl,
	apiLegalEntityProfileUrl,
} from "../constants/config";
import "core-js/stable";


async function PostFarmer(farmer) {
	try {
		let promise = await axios
			.post(
				apiFarmerUrl,
				{
					username: farmer.username,
					password: farmer.password,
					firstName: farmer.firstName,
					lastName: farmer.lastName,
					birthdate: farmer.birthdate
						? date.toISOString()
						: null,
					nationalCode: farmer.nationalCode,
					education: farmer.education,
					state: farmer.state,
					city: farmer.city,
					district: farmer.district,
					town: farmer.town,
					address: farmer.address,
					type: farmer.type,
				},
				{}
			)
			.then((res) => {
				console.log("res.data", res.data);
				if (res.data && res.data.succeeded) {
					return true;
				}
				return false;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from register" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function PutFarmer(farmer) {
	try {
		let promise = await axios
			.put(
				apiFarmerUrl,farmer,
				{}
			)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from register" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}
	return false;
}


async function PostLegalEntity(farmer) {
	try {
		console.log("farmer3", farmer);

		let promise = await axios
			.post(
				apiLegalEntityUrl,
				{
					username: farmer.username,
					password: farmer.password,
					firstName: farmer.firstName,
					lastName: farmer.lastName,
					companyName: farmer.companyName,
					companyAddress: farmer.companyAddress,
					companyPhone: farmer.companyPhone,
					companyPostcode: farmer.companyPostcode,
					companyEconomicCode: farmer.companyEconomicCode,
					companyNationalId: farmer.companyNationalId,
				},
				{}
			)
			.then((res) => {
				console.log("res.data", res.data);
				if (res.data && res.data.succeeded) {
					return true;
				}
				return false;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from register" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function PutLegalEntity(legalEntity) {
	try {

		let promise = await axios
			.put(
				apiLegalEntityUrl,
				legalEntity,
				{}
			)
			.then((res) => {
				return res.data
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from register" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}


async function GetFarmerProfile() {
	try {
		let promise = await axios
			.get(apiFarmerProfileUrl, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function GetLegalEntityProfile() {
	try {
		let promise = await axios
			.get(apiLegalEntityProfileUrl, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from Farmer" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}


async function GetTextValueList() {
	try {
		let promise = await axios
			.get(apiFarmerTextValueUrl, {})
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (err.response)
				console.log(
					"GetTextValueList " + err.response.data.Message
				);
		});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}
async function GetLegalEntityTextValueList() {
	try {
		let promise = await axios
			.get(apiLegalEntityTextValueUrl, {})
			.then((res) => {
				return res.data.data;
			})
			.catch((err) => {
				if (err.response)
					console.log(
						"catch from farm" + err.response.data.Message
					);
			});

		return promise;
	} catch (err) {
		console.log(err);
	}

	return false;
}

export const farmerService = {
	PostFarmer,
	PutFarmer,
	PostLegalEntity,
	GetFarmerProfile,
	GetLegalEntityProfile,
	PutLegalEntity,
	GetTextValueList,
	GetLegalEntityTextValueList,
};
