import axios from "axios";
import {
    apiDashboardUserCountUrl,
    apiDashboardCurrentUserAgentInfo,
    GetUserInfoWithPhoneUrl,
    GetUserIdentityCodeUrl,
    SendUserIdentityCodeUrl,
} from "../constants/config";

import "core-js/stable";

async function GetAdminDashboardCount() {
    try {
        let promise = await axios
            .post(apiDashboardUserCountUrl, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Banner" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetCurrentUserAgentInfo() {
    try {
        let promise = await axios
            .post(apiDashboardCurrentUserAgentInfo, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Banner" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetUserInfoWithPhone(phone, userType) {
    try {
        let data = {
            Phone: phone,
            UserType: userType
        };
        let promise = await axios
            .post(GetUserInfoWithPhoneUrl, data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Banner" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetUserIdentityCode(userType) {
    try {
        let promise = await axios
            .get(`${GetUserIdentityCodeUrl}/${userType}`, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Banner" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function SendUserIdentityCode(phone) {
    try {
        let data = {
            Phone : phone,
        };
        let promise = await axios
            .get(`${SendUserIdentityCodeUrl}/${phone}`,data, {})
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from Banner" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}


export const dashboardService = {
    GetAdminDashboardCount,
    GetCurrentUserAgentInfo,
    GetUserInfoWithPhone,
    GetUserIdentityCode,
    SendUserIdentityCode,
};


