import axios from "axios";
import {
    apiGetAllAppHelpMove ,
    apiGetByIdAppHelpMove,
    apiInsertAppHelpMove ,
    apiEditAppHelpMove ,
    apiDeleteAppHelpMove
} from "../constants/config";
import "core-js/stable";

async function GetAll(name) {
  try {
    let data = {
      Name: name,
    };
    let promise = await axios
      .post(apiGetAllAppHelpMove, data, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiGetByIdAppHelpMove}/${id}`;
    let promise = await axios
      .get(url, {}).then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(helpMove) {
  try {
    let promise = await axios
      .post(apiInsertAppHelpMove, helpMove,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err)
          console.log("catch from helpMove" , err);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(helpMove) {
  try {
    let promise = await axios
      .post(apiEditAppHelpMove, helpMove, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteAppHelpMove}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const appHelpMoveService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
};
