import {correspondencesService} from "../../services/correspondences.service";

const state = {};

const actions = {
  async GetAllUserTypeAccessToChat({}, { }) {
    let result = await correspondencesService.GetAllUserTypeAccessToChat();
    return result;
  },
  async GetAllUsersInfoRelated({}, { }) {
    let result = await correspondencesService.GetAllUsersInfoRelated();
    return result;
  },
  async GetUserChatHistory({}, { userType , searchText}) {
    let result = await correspondencesService.GetUserChatHistory(userType , searchText);
    return result;
  },
  async GetChatDetailWithUserId({}, { userAnswerId }) {
    let result = await correspondencesService.GetChatDetailWithUserId(userAnswerId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  correspondencesState: state,
  actions,
  mutations,
};
