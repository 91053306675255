import axios from "axios";
import {
    apiGetFinanciailAccountInfo,
    apiLatestIncreseAndDecrease,
    apiGetBuyServicePaymentWithAccount,
    apiGetAllBuyServiceDetailWithId,
    apiGetAllAccountCustomOfferCode,
    apiIncreseAccountInventory,
    apiGetTransationWithId,
    apiCheckDisCountCode
} from "../constants/config"
import "core-js/stable";

async function accountInfo(accountId) {
    try {
        let data = {
            accountId: accountId
        };
        let promise = await axios.post(apiGetFinanciailAccountInfo, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from AccountInfo' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function latestTransaction(accountId) {
    try {
        let data = {
            accountId: accountId
        };
        let promise = await axios.post(apiLatestIncreseAndDecrease, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from LatestTransaction' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function buyServicePaymentAccount() {
    try {
        let promise = await axios.post(apiGetBuyServicePaymentWithAccount, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from buyService Payment AccountId' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function buyServiceDetailWithBuyServiceId(buyServiceId) {
    try {
        let data = {
            buyServiceId: buyServiceId
        };
        let promise = await axios.post(apiGetAllBuyServiceDetailWithId, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function accountCustomOfferCode() {
    try {
        let promise = await axios.post(apiGetAllAccountCustomOfferCode, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function checkDisCountCode(accountId, code) {
    try {
        let model = {
            AccountId: accountId,
            Code: code
        };
        let promise = await axios.post(apiCheckDisCountCode, model, {}
        ).then((res) => {
            return res;
        }).catch((err) => {
            if (err.response)
                console.log('catch from farmUser' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function increseAccountInventory(accountId, amount, disCountId, callBackUrl) {
    try {
        let data = {
            accountId: accountId,
            amount: amount,
            disCountId: disCountId,
            callBackUrl: callBackUrl
        };
        let promise = await axios.post(apiIncreseAccountInventory, data, {}
        ).then((res) => {
            return {
                state: true,
                message: res.data,
            };
        }).catch((err) => {
            return {
                state: false,
                message: err.response.data
            };
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function GetTransationWithId(factId ,transId , idGet) {
    try {
        let data = {
            factId: factId,
            transId: transId,
            idGet : idGet
        };
        let promise = await axios.post(apiGetTransationWithId, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('increse Inventory Catch ' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
export const financialUserAccountService = {
    accountInfo,
    latestTransaction,
    buyServicePaymentAccount,
    buyServiceDetailWithBuyServiceId,
    accountCustomOfferCode,
    increseAccountInventory,
    GetTransationWithId,
    checkDisCountCode
};
