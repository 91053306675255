import {farmerReport} from "../../services/farmerReport.service";

const state = {};

const actions = {
  async Farmer_GetFarmDate({}, {}) {
    let result = await farmerReport.GetFarmDate();
    return result;
  },
  async Farmer_GetBuyService({}, {}) {
    let result = await farmerReport.GetBuyService();
    return result;
  },
  async Farmer_GetFinancialAccount({}, {}) {
    let result = await farmerReport.GetFinancialAccount();
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  reportState: state,
  actions,
  mutations,
};
