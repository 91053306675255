import axios from "axios";
import {
  apiAdvicerUrl,
  apiAdvicerProfileUrl,
  apiGetExpertiseProfileUrl,
  apiSaveExpertiseProfileUrl,
  apiAdvicerPartnerUrl,
} from "../constants/config";
import "core-js/stable";

async function GetAdvicerProfile() {
  try {
    let promise = await axios
      .get(apiAdvicerProfileUrl, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advicer" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAdvicerExpertiseProfile() {
  try {
    let promise = await axios
      .get(apiGetExpertiseProfileUrl, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advicer" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function PutAdvicer(advicer) {
  try {
    let promise = await axios
      .put(apiAdvicerUrl, advicer, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from register" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function SaveAdvicerExpertiseProfile(expertiseinfo) {
  try {
    let promise = await axios
      .post(apiSaveExpertiseProfileUrl, expertiseinfo, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAdvicerPartner(count) {
  try {
    let promise = await axios
      .post(apiAdvicerPartnerUrl, {Count : count})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
export const advicerService = {
  GetAdvicerProfile,
  PutAdvicer,
  GetAdvicerExpertiseProfile,
  SaveAdvicerExpertiseProfile,
  GetAdvicerPartner,
};
