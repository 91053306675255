import axios from "axios";
import {
  apiFarmerLegalEntityFarms,
  apiFarmerLegalEntityIncreaseInventoryAccount,
  apiFarmerLegalEntityBuySentinel,
  apiFarmerLegalEntityBuySentinelFaktorFarm,
  apiFarmerLegalEntityAdvicers,
  apiFarmerLegalEntityAdvicerFarms,
  apiFarmerLegalEntityFarmusers,
  apiFarmerLegalEntityFarmuserFarms,
  apiGetAllFarmerAdvicer,
  apiFarmerAdvicerDetailInfo,
  apiFarmerAdvicerGetInfoWithCode,
  apiFarmerAdvicerRegister,
  apiFarmerAdvicerAllFarms,
  apiFarmerAdvicerDelete,
  apiFarmerAdvicerDeleteFarm,
  apiFarmerAdvicerAddFarm,
  apiGetAllFarmerFarmuser,
  apiFarmerFarmuserDetailInfo,
  apiFarmerFarmuserGetInfoWithCode,
  apiFarmerFarmuserRegister, 
  apiFarmerFarmuserAllFarms,
  apiFarmerFarmuserDelete,
  apiFarmerFarmuserDeleteFarm,
  apiFarmerFarmuserAddFarm,
} from "../constants/config";
import "core-js/stable";
async function GetAllLegalEntityFarms() {
  try {
    let promise = await axios
      .post(apiFarmerLegalEntityFarms, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityIncreaseInventoryAccount() {
  try {
    let promise = await axios
      .post(apiFarmerLegalEntityIncreaseInventoryAccount, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityBuySentinel() {
  try {
    let promise = await axios
      .post(apiFarmerLegalEntityBuySentinel, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityBuySentinelFaktorFarm(buyServiceId) {
  try {
    let model = { BuyServiceId: buyServiceId };
    let promise = await axios
      .post(apiFarmerLegalEntityBuySentinelFaktorFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityAdvicers(searchName) {
  try {
    let model = {
      SearchName: searchName,
    };
    let promise = await axios
      .post(apiFarmerLegalEntityAdvicers, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityAdvicerFarms(advicerId) {
  try {
    let model = {
      AdvicerId: advicerId,
    };
    let promise = await axios
      .post(apiFarmerLegalEntityAdvicerFarms, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityFarmusers(searchName) {
  try {
    let model = {
      SearchName: searchName,
    };
    let promise = await axios
      .post(apiFarmerLegalEntityFarmusers, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function legalEntityFarmuserFarms(advicerId) {
  try {
    let model = {
      FarmuserId: advicerId,
    };
    let promise = await axios
      .post(apiFarmerLegalEntityFarmuserFarms, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

//FarmUser
async function GetAllFarmerFarmuser(searchName) {
  try {
    let model = {
      SearchName: searchName,
    };
    let promise = await axios
      .post(apiGetAllFarmerFarmuser, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerFarmuserFarms(farmuserId, canAdd) {
  try {
    let model = {
      FarmuserId: farmuserId,
      CanAdd: canAdd,
    };
    let promise = await axios
      .post(apiFarmerFarmuserAllFarms, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerFarmuserDetailInfo(farmuserId) {
  try {
    let model = {
      FarmUserId: farmuserId,
    };
    let promise = await axios
      .post(apiFarmerFarmuserDetailInfo, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerFarmuserGetInfoWithCode(code) {
  try {
    let model = {
      Code: code,
    };
    let promise = await axios
      .post(apiFarmerFarmuserGetInfoWithCode, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerFarmuserRegister(farmuserId) {
  try {
    let model = {
      FarmUserId: farmuserId,
    };
    let promise = await axios
      .post(apiFarmerFarmuserRegister, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
} 
async function FarmerFarmuserDelete(farmuserId) {
  try {
    let model = {
      FarmUserId: farmuserId,
    };
    let promise = await axios
      .post(apiFarmerFarmuserDelete, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmuserDeleteFarm(id) {
  try {
    let model = {
      Id: id,
    };
    let promise = await axios
      .post(apiFarmerFarmuserDeleteFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmuserAddFarm(farmuserId, farmId) {
  try {
    let model = {
      FarmUserId: farmuserId,
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiFarmerFarmuserAddFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

//Advicer
async function GetAllFarmerAdvicer(searchName) {
  try {
    let model = {
      SearchName: searchName,
    };
    let promise = await axios
      .post(apiGetAllFarmerAdvicer, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerAdvicerFarms(advicerId, canAdd) {
  try {
    let model = {
      AdvicerId: advicerId,
      CanAdd: canAdd,
    };
    let promise = await axios
      .post(apiFarmerAdvicerAllFarms, model, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerAdvicerDetailInfo(advicerId) {
  try {
    let model = {
      AdvicerId: advicerId,
    };
    let promise = await axios
      .post(apiFarmerAdvicerDetailInfo, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerAdvicerGetInfoWithCode(code) {
  try {
    let model = {
      Code: code,
    };
    let promise = await axios
      .post(apiFarmerAdvicerGetInfoWithCode, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerAdvicerRegister(advicerId) {
  try {
    let model = {
      AdvicerId: advicerId,
    };
    let promise = await axios
      .post(apiFarmerAdvicerRegister, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function FarmerAdvicerDelete(advicerId) {
  try {
    let model = {
      AdvicerId: advicerId,
    };
    let promise = await axios
      .post(apiFarmerAdvicerDelete, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function AdvicerDeleteFarm(id) {
  try {
    let model = {
      Id: id,
    };
    let promise = await axios
      .post(apiFarmerAdvicerDeleteFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function AdvicerAddFarm(advicerId, farmId) {
  try {
    let model = {
      AdvicerId: advicerId,
      FarmId: farmId,
    };
    let promise = await axios
      .post(apiFarmerAdvicerAddFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from GetAllLegalEntityFarmer" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}


export const connectionFarmerService = {
  GetAllLegalEntityFarms,
  legalEntityIncreaseInventoryAccount,
  legalEntityBuySentinel,
  legalEntityBuySentinelFaktorFarm,
  legalEntityAdvicers,
  legalEntityAdvicerFarms,
  legalEntityFarmusers,
  legalEntityFarmuserFarms,

  GetAllFarmerAdvicer,
  FarmerAdvicerFarms,
  FarmerAdvicerDetailInfo,
  FarmerAdvicerGetInfoWithCode,
  FarmerAdvicerRegister,
  FarmerAdvicerDelete,
  AdvicerDeleteFarm,
  AdvicerAddFarm,


  GetAllFarmerFarmuser,
  FarmerFarmuserDetailInfo,
  FarmerFarmuserGetInfoWithCode,
  FarmerFarmuserRegister, 
  FarmerFarmuserFarms,
  FarmerFarmuserDelete,
  FarmuserDeleteFarm,
  FarmuserAddFarm,
};
