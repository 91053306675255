import {farmUserService} from '../../services/farmUser.service';

const state = {

};

const actions = {
    async addFarmUser({}, { farmUser }) {
        farmUser.mobileNumber = '0' + farmUser.mobileNumber;
        let result = await farmUserService.Post(farmUser);
        return result;
    },
    async Gets({}, {}) {
        let result = await farmUserService.GetList();
        return result;
    },
    async GetProfile({}, {}) {
        let result = await farmUserService.GetProfile();
        return result;
    },
};//
const mutations = {};
export default {
    namespaced: true,
    farmUserState: state,
    actions,
    mutations
};
