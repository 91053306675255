import axios from "axios";
import { apiAdvicerAllEmployerFarm } from "../constants/config";
import "core-js/stable";

async function GetAdvicerAllEmployerFarm(userId, userType) {
  try {
    let model = {
      UserId: userId,
      UserType: userType,
    };
    let promise = await axios
      .post(apiAdvicerAllEmployerFarm, model, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from AdvicerExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
export const ConnectionAdvicerService = {
  GetAdvicerAllEmployerFarm,
};
