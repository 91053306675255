import axios from "axios";
import {
  apiGetAllTips,
  apiGetAllEmployerTips,
  apiGetAllTipsAndReply,
  apiGetTips,
  apiNewTips,
  apiNewTipsReply,
  apiEditTips,
  apiDeleteTipsFile,
  apiDeleteReplyFile,
  apiGetLastTips,
} from "../constants/config";
import "core-js/stable";

async function GetAllTips(farmId, allTips) {
  try {
    let promise = await axios
      .post(apiGetAllTips, { AllTips: allTips, FarmId: farmId })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
async function GetAllEmployerTips(userId, userType) {
  try {
    let model = {
      UserId: userId,
      UserType: userType,
    };
    let promise = await axios
      .post(apiGetAllEmployerTips, model)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
async function GetAllTipsAndReply(id) {
  try {
    let promise = await axios
      .post(apiGetAllTipsAndReply, { Id: id })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
async function GetTips(id) {
  try {
    let promise = await axios
      .get(`${apiGetTips}${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from GetTips" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function NewTips(tips) {
  try {
    let promise = await axios
      .post(apiNewTips, tips, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from tips" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function NewTipsReply(tipsReply) {
  try {
    let promise = await axios
      .post(apiNewTipsReply, tipsReply, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from tips" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function EditTips(tips) {
  try {
    let promise = await axios
      .post(apiEditTips, {
        Id: tips.id,
        Description: tips.description,
        Title: tips.title,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
async function DeleteTipsFile(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteTipsFile}${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function DeleteReplyFile(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteReplyFile}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetLastTips(userId, userType) {
  try {
    let promise = await axios
      .post(apiGetLastTips, { UserId: userId, UserType: userType })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
export const tipsService = {
  GetAllTips,
  GetAllEmployerTips,
  GetAllTipsAndReply,
  GetTips,
  NewTips,
  NewTipsReply,
  EditTips,
  DeleteTipsFile,
  DeleteReplyFile,
  GetLastTips,
};
