/* eslint-disable no-unused-vars */
import {connectionFarmerService} from "../../services/connectionFarmer.service";

const state = {};
const actions = {
    async GetAllFarmerAdvicer({ state }, { searchName }) {
        let result = await connectionFarmerService.GetAllFarmerAdvicer(searchName);
        return result;
    },
    async FarmerAdvicerFarms({ state }, {advicerId , canAdd}) {
        let result = await connectionFarmerService.FarmerAdvicerFarms(advicerId , canAdd);
        return result;
    },
    async FarmerAdvicerDetailInfo({ state }, { advicerId}) {
        let result = await connectionFarmerService.FarmerAdvicerDetailInfo(advicerId);
        return result;
    },
    async FarmerAdvicerGetInfoWithCode({ state }, { code }) {
        let result = await connectionFarmerService.FarmerAdvicerGetInfoWithCode(code);
        return result;
    },
    async FarmerAdvicerRegister({ state }, { advicerId }) {
        let result = await connectionFarmerService.FarmerAdvicerRegister(advicerId);
        return result;
    },
    async FarmerAdvicerLatestTips({ state }, { advicerId }) {
        let result = await connectionFarmerService.FarmerAdvicerLatestTips(advicerId);
        return result;
    },
    async FarmerAdvicerDelete({ state }, {advicerId}) {
        let result = await connectionFarmerService.FarmerAdvicerDelete(advicerId);
        return result;
    },
    async AdvicerDeleteFarm({ state }, {id}) {
        let result = await connectionFarmerService.AdvicerDeleteFarm(id);
        return result;
    },
    async AdvicerAddFarm({ state }, {advicerId,farmId}) {
        let result = await connectionFarmerService.AdvicerAddFarm(advicerId,farmId);
        return result;
    },
};
const mutations = {};


export default {
    namespaced: true,
    connectionFarmerAdvicerState: state,
    actions,
    mutations,
};
