import axios from "axios";
import {
  apiFarmUserUrl,
  apiFarmUserProfileUrl,
  apiGetFarmUserExpertiseUrl,
  apiSaveFarmUserExpertiseInfoUrl,
  apiFarmUserPartnerUrl,
} from "../constants/config";
import "core-js/stable";

async function Post(farmUser) {
  try {
    let promise = await axios
      .post(
        apiFarmUserUrl,
        {
          firstName: farmUser.firstName,
          lastName: farmUser.lastName,
          UserName: farmUser.mobileNumber,
          password: farmUser.password,
          farmsId: farmUser.farms,
        },
        {}
      )
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetProfile() {
  try {
    let promise = await axios
      .get(apiFarmUserProfileUrl, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Put(farmUser) {
  try {
    let promise = await axios
      .put(apiFarmUserUrl, farmUser, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetList() {
  try {
    var url = apiFarmUserUrl + "?pageNumber= 1&&pageSize= 100";
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from farmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetFarmUserExpertiseProfile() {
  try {
    let promise = await axios
      .get(apiGetFarmUserExpertiseUrl, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from FarmUser" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function SaveFarmUserExpertiseProfile(expertiseinfo) {
  try {
    let promise = await axios
      .post(apiSaveFarmUserExpertiseInfoUrl, expertiseinfo, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log(
            "catch from FarmUserExpertise" + err.response.data.Message
          );
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetFarmUserPartner(count) {
  try {
    let promise = await axios
      .post(apiFarmUserPartnerUrl, {Count : count})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
}
export const farmUserService = {
  Post,
  GetList,
  Put,
  GetProfile,
  GetFarmUserExpertiseProfile,
  SaveFarmUserExpertiseProfile,
  GetFarmUserPartner,
};
