import axios from "axios";
import {
  apiBannerProductGetById,
  apiBannerProductFilter,
  apiBannerProductGetAll,
  apiBannerProductInsert,
  apiBannerProductEdit,
  apiBannerProductDelete,
  apiBannerProductGetForDashbord,
  apiBannerProductGetAllClient,
} from "../constants/config";
import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiBannerProductGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize,productId, name , label , description , type , userType) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      ProductId : productId,
      Name: name,
      Label : label,
      Description : description,
      Type : type ,
      UserType : userType
    };
    let promise = await axios
      .post(apiBannerProductFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetForDashbord() {
  try {
    let promise = await axios
      .post(apiBannerProductGetForDashbord, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAllClient() {
  try {
    let promise = await axios
      .post(apiBannerProductGetAllClient, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiBannerProductGetById}/${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(bannerProduct) {
  try {
    let promise = await axios
      .post(apiBannerProductInsert, bannerProduct ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(bannerProduct) {
  try {
    let promise = await axios
      .post(apiBannerProductEdit, bannerProduct ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiBannerProductDelete}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Banner" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const bannerProductService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
  GetForDashbord,
  GetAllClient
};
