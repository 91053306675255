import axios from "axios";
import {
  apiGeoDataBaseTypeGetById,
  apiGeoDataBaseTypeGetAll,
  apiGeoDataBaseTypeInsert,
  apiGeoDataBaseTypeEdit,
  apiGeoDataBaseTypeDelete,
} from "../constants/config";

import "core-js/stable";

async function GetAll() {
  try {
    let promise = await axios
      .post(apiGeoDataBaseTypeGetAll, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiGeoDataBaseTypeGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(title) {
  try {
    let data = { Title: title };
    let promise = await axios
      .post(apiGeoDataBaseTypeInsert, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(geoDataBaseType) {
  try {
    let promise = await axios
      .post(
        apiGeoDataBaseTypeEdit,
        {
          Id: geoDataBaseType.id,
          Title: geoDataBaseType.title,
        },
        {}
      )
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiGeoDataBaseTypeDelete}/${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const geoDataBaseTypeService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
};
