/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */

import {connectionFarmerService} from "../../services/connectionFarmer.service";
import {farmService} from "../../services/farm.service";

const state = {};
const actions = {
  async GetAllLegalEntityFarms({ state }, { userType }) {
    let result = await connectionFarmerService.GetAllLegalEntityFarms();
    return result;
  },
  async GetFarmDetail({ state }, { id }) {
    let result = await farmService.Get(id).catch((err) => console.log(err));
    return result;
  },
  async legalEntityIncreaseInventoryAccount({ state }, { userType }) {
    let result =
      await connectionFarmerService.legalEntityIncreaseInventoryAccount();
    return result;
  },
  async legalEntityBuySentinel({ state }, { userType }) {
    let result = await connectionFarmerService.legalEntityBuySentinel();
    return result;
  },
  async legalEntityBuySentinelFaktorFarm(
    { state },
    { userType, buyServiceId }
  ) {
    let result = await connectionFarmerService.legalEntityBuySentinelFaktorFarm(
      buyServiceId
    );
    return result;
  },
  async legalEntityAdvicers({ state }, { userType, searchName }) {
    let result =await connectionFarmerService.legalEntityAdvicers(searchName);
    return result;
  },
  async legalEntityAdvicerFarms({ state }, { userType, advicerId }) {
    let result =await connectionFarmerService.legalEntityAdvicerFarms(advicerId);
    return result;
  },
  async legalEntityAdvicerLastFarmTips({ state }, { userType, farmId }) {
    let result =await connectionFarmerService.legalEntityAdvicerLastFarmTips(farmId);
    return result;
  },

  async legalEntityFarmusers({ state }, { searchName }) {
    let result = await connectionFarmerService.legalEntityFarmusers(searchName);
    return result;
  },
  async legalEntityFarmuserFarms({ state }, { farmuserId }) {
    let result = await connectionFarmerService.legalEntityFarmuserFarms(
      farmuserId
    );
    return result;
  },
  async legalEntityFarmuserLastFarmTips({ state }, { farmId }) {
    let result = await connectionFarmerService.legalEntityFarmuserLastFarmTips(
      farmId
    );
    return result;
  },
};
const mutations = {};

export default {
  namespaced: true,
  connectionFarmerLegalEntityState: state,
  actions,
  mutations,
};
