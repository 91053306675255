import {tiketService} from "../../services/tiket.service";

const state = {};

const actions = {
  async GetAll() {
    let result = await tiketService.GetAll();
    return result;
  },
  async GetAllWithUser() {
    let result = await tiketService.GetAllWithUser();
    return result;
  },
  async GetAnswerByTiketId({}, { tiketId}) {
    let result = await tiketService.GetAnswerByTiketId(tiketId);
    return result;
  },
  async GetById({}, { tiketId }) {
    let result = await tiketService.GetById(tiketId);
        return result;
  },
  async PostAnswer({}, { tiket }) {
    let result = await tiketService.PostAnswer(tiket);
    return result;
  },
  async PostNew({}, { tiket }) {
    let result = await tiketService.PostNew(tiket);
    return result;
  },
  async ChangeState({}, { tiketId, state }) {
    let result = await tiketService.ChangeState(tiketId, state);
    return result;
  },
  async CloseTiket({}, {tiketId}) {
    let result = await tiketService.CloseTiket(tiketId);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  adviceState: state,
  actions,
  mutations,
};
