import "core-js/stable";
import Vue from "vue";
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from "../node_modules/@coreui/vue-pro/src/index.js";
import App from "./App";
import router from "./router/index";
import {iconsSet as icons} from "./assets/icons/icons.js";
import store from "./store/index";
import i18n from "./i18n.js";
import interceptorsSetup from "./interceptors";
import "./registerServiceWorker";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import {library} from "@fortawesome/fontawesome-svg-core";
// import font-awesome icons
import {faEye, faEyeSlash, fas} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import DisableAutocomplete from "vue-disable-autocomplete";
import Notifications from "vue-notification";
import VueElementLoading from "vue-element-loading";
import VueConfirmDialog from "vue-confirm-dialog";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import {AgGridVue} from "ag-grid-vue";
import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
import {LicenseManager} from "@ag-grid-enterprise/core";
import Skycon from "vue-skycons";
import vuelidate from "vuelidate";
import excel from "vue-excel-export";

LicenseManager.setLicenseKey(
  "DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6"
);

library.add(fas);
library.add(faEye);
library.add(faEyeSlash);
interceptorsSetup();
Vue.use(vuelidate);
Vue.use(CoreuiVuePro);
Vue.use(DisableAutocomplete);
Vue.use(Notifications);
Vue.use(VueConfirmDialog);
Vue.use(require("vue-jalali-moment"));
Vue.use(require("vue-persian-calendar"));
Vue.use(excel);
Vue.prototype.$log = console.log.bind(console);
Vue.component("date-picker", VuePersianDatetimePicker);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("vue-element-loading", VueElementLoading);
Vue.component("VueModal", VueModal);
Vue.component("AgGridVue", AgGridVue);
Vue.component("vue-skycons", Skycon);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  i18n,
  template: "<App/>",
  components: {
    App,
  },
});
