import axios from "axios";
import {
  apiProductGetById,
  apiProductFilter,
  apiProductGetAll,
  apiProductInsert,
  apiProductEdit,
  apiProductDelete,
  apiProductGatAllType
} from "../constants/config";

import "core-js/stable";



async function GetAllProductType(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiProductGatAllType, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiProductGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, name , productTypeId , greenAlways) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      Name: name,
      ProductTypeId : productTypeId,
      GreenAlways : greenAlways
    };
    let promise = await axios
      .post(apiProductFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiProductGetById}/${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(product) {
  try {
    let promise = await axios
      .post(apiProductInsert, product ,{ headers: {'Content-Type': 'multipart/form-data'}  })
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(product) {
  try {
    let promise = await axios
      .post(apiProductEdit, product ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiProductDelete}/${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Product" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const ProductService = {
  GetAll,
  GetAllProductType,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
};
