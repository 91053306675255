import {geoDataBaseTypeService} from "../../services/geoDataBaseType.service";

const state = {};

const actions = {
  async GetAll({}, {}) {
    let result = await geoDataBaseTypeService.GetAll();
    return result;
  },
  async GetById({}, { id }) {
    let result = await geoDataBaseTypeService.GetById(id);
        return result;
  },
  async Insert({}, { title }) {
    let result = await geoDataBaseTypeService.Insert(title);
    return result;
  },
  async Edit({}, { geoDataBaseType }) {
      debugger
    let result = await geoDataBaseTypeService.Edit(geoDataBaseType);
    return result;
  },
  async Delete({}, { id }) {
    let result = await geoDataBaseTypeService.Delete(id);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  countryState: state,
  actions,
  mutations,
};
