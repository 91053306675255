import axios from "axios";
import {
    apiUserTypeCostUrlGetAll,
    apiUserTypeCostUrlGetById,
    apiUserTypeCostUrlInsert,
    apiUserTypeCostUrlEdit,
    apiUserTypeCostUrlDelete,
} from "../constants/config";

import "core-js/stable";

async function GetAll() {
    try {
        let data = {
            PageNumber: 0,
            PageSize: 0,
        };
        let promise = await axios
            .post(apiUserTypeCostUrlGetAll, data, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from UserTypeCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }
    return false;
}
async function GetById(id) {
    try {
        let url = `${apiUserTypeCostUrlGetById}/${id}`;
        let promise = await axios
            .get(url, {}).then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from UserTypeCost" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Insert(userTypeCost) {
    try {
        let promise = await axios
            .post(apiUserTypeCostUrlInsert, userTypeCost, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from UserTypeCost" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Edit(userTypeCost) {
    try {
        let promise = await axios
            .post(apiUserTypeCostUrlEdit, userTypeCost, {})
            .then((res) => {
                if (res.data && res.data.succeeded) {
                    return true;
                }
                return false;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from country" + err.response.data.Message);
            });
        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
async function Delete(id) {
    try {
        let promise = await axios
            .get(`${apiUserTypeCostUrlDelete}/${id}`, {})
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                if (err.response)
                    console.log("catch from userTypeCost" + err.response.data.Message);
            });

        return promise;
    } catch (err) {
        console.log(err);
    }

    return false;
}
export const userTypeCostService = {
    GetAll,
    GetById,
    Insert,
    Edit,
    Delete,
};
