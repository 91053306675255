import { isAuthGuardActive } from "../constants/config";
import { setCurrentUser, getCurrentUser } from ".";
export default (to, from, next) => {
  try {
    if (to.matched.some((record) => record.meta.loginRequired)) {
      if (isAuthGuardActive) {
        const user = getCurrentUser();
        if (user) {
          next(); 
        } else {
          setCurrentUser(null);
          next("/pages/login");
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } catch (err) {
    console.log("AuthGuard",err);
  }
};
