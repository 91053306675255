import axios from "axios";
import {
  apiProvinceGetById,
  apiProvinceFilter,
  apiProvinceGetAll,
  apiProvinceInsert,
  apiProvinceEdit,
  apiProvinceDelete,
  apiGetProvinceAllCountry,
} from "../constants/config";

import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiProvinceGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, name, countryId) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      Name: name,
      CountryId: countryId,
    };
    let promise = await axios
      .post(apiProvinceFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiProvinceGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(province) {
  try {
    let data = {
      Name: province.name,
      CountryId: province.countryId,
      Label: province.label,
    };
    debugger;
    let promise = await axios
      .post(apiProvinceInsert, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(Province) {
  try {
    let promise = await axios
      .post(
        apiProvinceEdit,
        {
          Id: Province.id,
          Name: Province.name,
          CountryId: Province.countryId,
          Label: Province.label,
        },
        {}
      )
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiProvinceDelete}/${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Province" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetAllCountry(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    let promise = await axios
      .post(apiGetProvinceAllCountry, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}

export const ProvinceService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
  GetAllCountry,
};
