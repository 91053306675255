/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import {advicerService} from "../../services/advicer.service";
import {ConnectionAdvicerService} from "../../services/connectionAdvicer.service";
import {farmUserService} from "../../services/farmUser.service";
import {ConnectionFarmuserService} from "../../services/connectionFarmuser.service";
import {tipsService} from "../../services/tips.service";

const state = {};
const actions = {
  async GetAllPartner({ state }, { currentUserType }) {
    if (currentUserType === "Advicer") {
      let result = await advicerService.GetAdvicerPartner(0);
      return result;
    } else if (currentUserType === "FarmUser") {
      let result = await farmUserService.GetFarmUserPartner(0);
      return result;
    } else {
      return false;
    }
  },
  async GetAllEmployerTips({ state }, { userId, userType }) {
    let result = await tipsService.GetAllEmployerTips(userId, userType);
    return result;
  },
  async GetAllPartnerFarms({ state }, { userId, userType, currentUserType }) {
    if (currentUserType === "Advicer") {
      let result = await ConnectionAdvicerService.GetAdvicerAllEmployerFarm(
        userId,
        userType
      );
      return result;
    } else if (currentUserType === "FarmUser") {
      let result = await ConnectionFarmuserService.GetFarmuserAllEmployerFarm(
        userId,
        userType
      );
      return result;
    } else {
      return false;
    }
  },

  async GetAllTips({ state }, { farmId, allTips }) {
    let result = await tipsService.GetAllTips(farmId, allTips);
    return result;
  },
  async GetAllTipsAndReply({ state }, { id }) {
    let result = await tipsService.GetAllTipsAndReply(id);
    return result;
  },
  async GetTips({ state }, { id }) {
    let result = await tipsService.GetTips(id);
    return result;
  },
  async NewTips({ state }, { tips }) {
    let result = await tipsService.NewTips(tips);
    return result;
  },
  async NewTipsReply({ state }, { tipsReply }) {
    let result = await tipsService.NewTipsReply(tipsReply);
    return result;
  },
  async EditTips({ state }, { editTips }) {
    let result = await tipsService.EditTips(editTips);
    return result;
  },
  async DeleteTipsFile({ state }, { id }) {
    let result = await tipsService.DeleteTipsFile(id);
    return result;
  },
  async DeleteReplyFile({ state }, { id }) {
    let result = await tipsService.DeleteReplyFile(id);
    return result;
  },
  async GetLastTips({ state }, { userId, userType }) {
    let result = await tipsService.GetLastTips(userId, userType);
    return result;
  },
};
const mutations = {};

export default {
  namespaced: true,
  tipsState: state,
  actions,
  mutations,
};
