import { weatherService } from "../../services/weather.service";

const state = {};

const actions = {
  async GetMessage({}, { condition }) {
    let result = await weatherService.GetMessage(condition);
    return result;
  },
  async GetWeather({}, { lat, lon }) {
    let result = await weatherService.CallOpenWeatherMap(lat, lon);
    return result;
  },
};
const mutations = {};
export default {
  namespaced: true,
  weatherState: state,
  actions,
  mutations,
};
