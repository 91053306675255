import axios from "axios";
import {
    apiReportGetAllTransaction,
    apiReportGetAllBuyServiceWithUserId,
    apiReportGetAllBuyService,
    apiReportGetBuyServiceDetail,
    apiReportGetFarmInfo,
    apiReportGetAllFarmAndDateTimeCurrentUser,
    apiReportGetBuyServiceInfoWithDetailId,
    //#endregion
} from "../constants/config";
import "core-js/stable";


async function ReportGetAllTransaction() {
    try {
        let promise = await axios.post(apiReportGetAllTransaction, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetAllTransaction' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetAllBuyServiceWithUserId() {
    try {
        let promise = await axios.post(apiReportGetAllBuyServiceWithUserId, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetAllBuyServiceWithUserId' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetAllBuyService() {
    try {
        let promise = await axios.post(apiReportGetAllBuyService, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetAllBuyService' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetBuyServiceDetail(buyServiceId) {
    try {
        let data = {
            BuyServiceId: buyServiceId
        };
        let promise = await axios.post(apiReportGetBuyServiceDetail, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetBuyServiceDetail' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetFarmInfoWithBuyServiceId(buyServiceId) {
    try {
        let data = {
            BuyServiceId: buyServiceId
        };
        let promise = await axios.post(apiReportGetFarmInfo, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetFarmInfo' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetAllFarmAndDateTimeCurrentUsService() {
    try {
        let promise = await axios.post(apiReportGetAllFarmAndDateTimeCurrentUser, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetAllFarmAndDateTimeCurrentUs' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}
async function ReportGetBuyServiceInfoWithDetailId(buyServiceDetailId) {
    try {
        let data = {
            buyServiceDetailId: buyServiceDetailId
        };
        let promise = await axios.post(apiReportGetBuyServiceInfoWithDetailId, data, {}
        ).then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response)
                console.log('catch from ReportGetBuyServiceInfoWithDetailId' + err.response.data.Message);
        });
        return promise
    } catch (err) {
        console.log(err)
    }

    return false;
}


export const financailUserReportService = {
    ReportGetAllTransaction,
    ReportGetAllBuyServiceWithUserId,
    ReportGetAllBuyService,
    ReportGetBuyServiceDetail,
    ReportGetFarmInfoWithBuyServiceId,
    ReportGetAllFarmAndDateTimeCurrentUsService,
    ReportGetBuyServiceInfoWithDetailId,
};
