import axios from "axios";
import {
  apiCountryGetById,
  apiCountryFilter,
  apiCountryGetAll,
  apiCountryInsert,
  apiCountryEdit,
  apiCountryDelete,
} from "../constants/config";

import "core-js/stable";

async function GetAll(pageNumber, pageSize) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
    };
    debugger;
    let promise = await axios
      .post(apiCountryGetAll, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetAllWithFilter(pageNumber, pageSize, name) {
  try {
    let data = {
      PageNumber: pageNumber,
      PageSize: pageSize,
      Name: name,
    };
    let promise = await axios
      .post(apiCountryFilter, data, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetById(id) {
  try {
    let url = `${apiCountryGetById}/${id}`;
    let promise = await axios
      .get(url , {}).then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
        return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(country) {
  try {
    let data = { Name: country.name , Label : country.label };
    let promise = await axios
      .post(apiCountryInsert, data, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(country) {
  try {
    let promise = await axios
      .post(apiCountryEdit,
        {
          Id : country.id,
          Name : country.name,
          Label : country.label
        }, {})
      .then((res) => {
        if (res.data && res.data.succeeded) {
          return true;
        }
        return false;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiCountryDelete}/${id}`, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const countryService = {
  GetAll,
  GetAllWithFilter,
  GetById,
  Insert,
  Edit,
  Delete,
};
