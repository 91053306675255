import axios from "axios";
import {
    apiGetAllHelpQuestion ,
    apiGetByIdHelpQuestion,
    apiInsertHelpQuestion ,
    apiEditHelpQuestion ,
    apiDeleteHelpQuestion
} from "../constants/config";
import "core-js/stable";

async function GetAll(answer,question) {
  try {
    let data = {
        Answer: answer,
      Question: question,
    };
    let promise = await axios
      .post(apiGetAllHelpQuestion, data, {})
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiGetByIdHelpQuestion}/${id}`;
    let promise = await axios
      .get(url, {}).then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(helpQuestion) {
  try {
    let promise = await axios
      .post(apiInsertHelpQuestion, helpQuestion,  {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(helpQuestion) {
  try {
    let promise = await axios
      .post(apiEditHelpQuestion, helpQuestion,  {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiDeleteHelpQuestion}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from advice" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
export const helpQuestionService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
};
