/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import {farmUserService} from "../../services/farmUser.service";
import {ConnectionFarmuserService} from "../../services/connectionFarmuser.service";

const state = {};
const actions = {
  async GetAllPartner({ state }, {}) {
    let result = await farmUserService.GetFarmUserPartner(0);
    return result;
  },
  async GetAllPartnerFarms({ state }, { userId, userType }) {
    let result = await ConnectionFarmuserService.GetFarmuserAllEmployerFarm(
      userId,
      userType
    );
    return result;
  },
};
const mutations = {};

export default {
  namespaced: true,
  connectionFarmuserState: state,
  actions,
  mutations,
};
