import axios from "axios";
import {
  apiIndicatorGetById,
  apiIndicatorGetAll,
  apiIndicatorInsert,
  apiIndicatorEdit,
  apiIndicatorDelete,
  apiIndicatorGetState,
  apiIndicatorSaveState,
  apiChangeUserIndicatorAccess,
} from "../constants/config";

import "core-js/stable";

async function GetAll() {
  try {
    let promise = await axios
      .post(apiIndicatorGetAll,{PageNumber : 0 ,PageSize : 0}, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function GetById(id) {
  try {
    let url = `${apiIndicatorGetById}/${id}`;
    let promise = await axios
      .get(url, {})
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Country" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Insert(indicator) {
  try {
    let promise = await axios
      .post(apiIndicatorInsert,  indicator ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from GeoDataBase" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Edit(indicator) {
  try {
    let promise = await axios
      .post(apiIndicatorEdit, indicator ,{ headers: {'Content-Type': 'multipart/form-data'} })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from indicator" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function Delete(id) {
  try {
    let promise = await axios
      .get(`${apiIndicatorDelete}/${id}`, {})
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response)
          console.log("catch from Indicator" + err.response.data.Message);
      });

    return promise;
  } catch (err) {
    console.log(err);
  }

  return false;
}
async function GetState(id) {
  try {
    let data = {
      Id : id
    };
    let promise = await axios
      .post(apiIndicatorGetState,  data , {})
      .then((res) => {
        return res;
      }).catch((err) => {
        if (err.response)
          console.log("catch from GeoDataBase" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function SaveState(indicatorState) {
  try {
    let promise = await axios
      .post(apiIndicatorSaveState,  indicatorState , {})
      .then((res) => {
        return res;
      }).catch((err) => {
        if (err.response)
          console.log("catch from GeoDataBase" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}
async function ChangeUserIndicatorAccess(id) {
  try {
    let promise = await axios
      .post(apiChangeUserIndicatorAccess,  {userId : id} , {})
      .then((res) => {
        return res;
      }).catch((err) => {
        if (err.response)
          console.log("catch from GeoDataBase" + err.response.data.Message);
      });
    return promise;
  } catch (err) {
    console.log(err);
  }
  return false;
}


export const indicatorService = {
  GetAll,
  GetById,
  Insert,
  Edit,
  Delete,
  GetState,
  SaveState,
  ChangeUserIndicatorAccess,
};
